import "./searchWidget.css";
import { useRef, useEffect } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import useAuth from "../../auth/useAuth";
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  FormControl,
  Nav,
  NavDropdown,
  Navbar,
} from "react-bootstrap";

export default function SearchWidget({ search, setSearch , typefilter, setTypefilter }) 
{


  const deleteSearch = ()=>{
    setSearch("");
    inputRef.current.focus();
  }


  const auth = useAuth();
  const inputRef = useRef();
  useEffect(() => {
    inputRef.current.focus();
  }, [auth.searchi]);

  return (
    <Container className="searchForm" onSubmit={(e) => e.preventDefault()}>

      <Form className="d-flex">
        <Navbar>
            <Nav>
              <NavDropdown title="Filter">
                <NavDropdown.Item
                 onClick={(e)=>{
                  inputRef.current.placeholder="By address"
                  inputRef.current.focus();
                  setTypefilter("projectAddress")    
                  }}
                 >
                  By Adrress
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={()=>{
                  inputRef.current.placeholder="By company"
                  inputRef.current.focus();
                  setTypefilter("company")   
                }}>
                  By Company
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={()=>{
                  inputRef.current.placeholder="By status"
                  inputRef.current.focus();
                  setTypefilter("status")  
                }} >
                  By Status
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={()=>{
                  inputRef.current.placeholder="By project owner"
                  inputRef.current.focus();
                  setTypefilter("client")  
                }} >
                  By project owner
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={()=>{
                  inputRef.current.placeholder="By date created"
                  inputRef.current.focus();
                  setTypefilter("dataCreated")                   
                }} >
                  By date created
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
        
            <FormControl
              placeholder="By address"
              id="search"
              type="search"
              role="searchbox"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              ref={inputRef}
              style={{width:'500px'}}
            />
            {search &&  <spam style={{position:'absolute', marginLeft:'530px'}}>
              <a href="#"><DeleteForeverIcon  style={{color:'red'}} onClick={deleteSearch}/></a>
            </spam>}
           
            
            <Button
              variant="outline-primary"
              type="submit"
              style={{
                marginLeft:'5px',
              }}
            >
              Search
            </Button>
         </Navbar>
      </Form>

    </Container>
  );
}
