import { useState, useEffect, useRef } from 'react';
import useAuth from '../../auth/useAuth';
import Title from '../../components/title/Title';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Crudtableclient from '../../components/crudtableclient/Crudtableclient';
import {
  Form,
  Button,
  FloatingLabel,
  FormGroup,
  Label,
  Container,
  Row,
  Col,
  Table,
  Modal,
  Nav,
  Navbar,
  NavDropdown,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import 'react-day-picker/dist/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './moduleadmin.css';
import SearchAdmin from '../../components/searchAdmin/SearchAdmin';

/////////////////SEPARATOR/////////////////////

const companiestosingleobject = [
  'GMBC',
  'Wise Builders',
  'Diego Valencia',
  'Bay Remodeling',
  'InnovoBuilders',
  'Liane Carter Interiors',
  'Nuvision Construction',
  'Oasis Home Design',
  'Oro Coast Builders',
  'Velvet Home Staging',
  'Tiffany',
  'Sunny',
  'Sueling',
  'Karen',
  'Mr & Mrs. Construction & Remodeling',
  'Deco Builders',
  'Independent',
  'Architec or Home Designer',
  'Vrealism',
  'A.O Positive Solutions',
  'Arcus Architecture',
  'ATC Design Center',
  'Baron Constructions',
  'BROTHER AND BROTHER BUILDERS',
  'CA Bear Constructions',
  'DelSur Construction',
  'Direct Home Remodeling Inc',
  'Edri Construction ',
  'Future Vision Remodeling',
  'Han DesignStudio',
  'Home by Tom',
  'Homeowners',
  'Lecut Construction',
  'Nova Builders',
  'On Design & Remodeling',
  'Prime Renovation Homes',
  'QA Buillders',
  'Re-Model Design & Build Co',
  'Realty Builders',
  'Skytech Builders',
  'The Building Company',
  'Wealthentech Inc',
];

const initialDb = [
  {
    id: null,
    cli_name: '',
    cli_email: '',
    cli_phone: '',
    company: '',
    cli_active: '',
    role: '',
    type: '',
  },
];

/////////////////SEPARATOR/////////////////////

const Moduleadmin = () => {
  const [db, setDb] = useState(initialDb);
  const type = window.localStorage.getItem('type');
  const navigate = useNavigate();
  const auth = useAuth();
  const [employees, setEmployees] = useState(false);

  const BringInfoByClient = async () => {
    const feis = await fetch(
      'https://node.vrealism.com:5000/api/admon/clients',
      {
        method: 'POST',
        body: JSON.stringify({
          type,
        }),
        headers: { 'Content-Type': 'application/json' },
      },
    );
    const jotason = await feis.json();

    console.log(jotason);

    const data = jotason.map((items) => ({
      id: items.cli_id,
      cli_name: items.cli_name,
      cli_email: items.cli_email,
      company: companiestosingleobject[items.cli_id - 1],
      cli_active: items.cli_active,
      role: items.role,
      type: items.type,
    }));

    setDb(data);

    console.log(data);
  };

  useEffect(() => {
    BringInfoByClient();
  }, [type]);

  const handleClick = (e) => {
    e.preventDefault();
    navigate('/edit');
  };

  //*********************************************************** //
  ////////////////////SEPARATOR///////////////////////////////////
  //*********************************************************** //
  return (
    <Container className="adminmodule">
      <Row style={{ marginTop: '10px' }}>
        <Col>
          <Title title="Administrators" />
        </Col>
      </Row>
      <Container
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center',
          justifyItems: 'center',
          backgroundColor: '#a2bdd2',
          /* border: '2px dotted green', */
        }}
      >
        <row>
          <Col>
            <SearchAdmin />
          </Col>
        </row>
        <row>
          <a
            href="#"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyItems: 'flex-end',
              alignItems: 'center',
              width: '120px',
              textDecoration: 'none',
            }}
          >
            <Col>
              <PersonAddIcon onClick={(e) => handleClick(e)} />
            </Col>
            <Col style={{ fontWeight: 'bold' }}>CREATE</Col>
          </a>
        </row>
      </Container>

      <br />

      {db && (
        <Container /* style={{ border: 'dotted red 2px' }} */>
          <Container style={{ display: 'flex', flexDirection: 'row' }}>
            <Crudtableclient datax={db} />
          </Container>
        </Container>
      )}
    </Container>
  );
};

export default Moduleadmin;
