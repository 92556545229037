import { useState, useEffect, useRef } from "react";
import useAuth from "../../auth/useAuth";
import {
  Form,
  Button,
  FloatingLabel,
  FormGroup,
  Label,
  Container,
  Row,
  Col,
  Table,
  Modal,
} from "react-bootstrap";
import { useNavigate} from "react-router-dom";
import moment from 'moment';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import InfoIcon from "@mui/icons-material/Info";
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import StorageIcon from "@mui/icons-material/Storage";
import { AddressAutoComplete } from "../../components/addressautocomplete/AddressAutoComplete";
import Geocode from "react-geocode";
import {
  GoogleMap,
  LoadScript,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";
import "bootstrap/dist/css/bootstrap.min.css";
import "./makeproject.css";
import { setDay } from "date-fns/esm";
const initialboxes = [
  {
    id: 1,
    checked: false,
    item: "Floor Plan",
  },
  {
    id: 2,
    checked: false,
    item: "Site Plan",
  },
  {
    id: 3,
    checked: false,
    item: "Elevation",
  },
  {
    id: 4,
    checked: false,
    item: "Roof Plan(Drone)",
  },
  {
    id: 5,
    checked: false,
    item: "Drone Video",
  },
  {
    id: 6,
    checked: false,
    item: "Drone Photography",
  },
];

const initialForm = {
  address:"",
  housesqft:"",
  homeownername:"",
  homeownerphone:"",
  homeownermail:"",
  description:"",
  jobday:"",
  timeday:"",
};

const MakeProject = () => {
  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const auth = useAuth();
  auth.setClient(window.localStorage.getItem('cli_name'));
  const inputRef = useRef();
  const inputCompany = useRef();
  const inputClients = useRef();
  const [homeaddress, setHomeaddress] = useState("");
  const [jobday, setJobday] = useState();
  const [timeday, setTimeday] = useState();
  const [liberty, setLiberty] = useState("4 Pennsylvania Plaza, New York, NY");
  const [homeownername, setHomeownername] = useState("");
  const [homeownerphone, setHomeownerphone] = useState("");
  const [homeownermail, setHomeownermail] = useState("");
  const [housesqft, setHousesqft] = useState("");
  const [projectownername, setProjectownername] = useState("");
  const [clientid, setClientid] = useState("");
  const [companyrequest, setCompanyrequest] = useState("");
  const [projectdescription, setProjectdescription] = useState("");
  const [currentdate, setCurrentdate] = useState(new Date());
  const [checkboxes, setCheckboxes] = useState(initialboxes);
  const [clients, setClients] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [company2client, setCompany2client] = useState("");
  const [results, setResults] = useState([]);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [issending, setIssending] = useState(false);
  const [zipcode, setZipcode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [independentclient, setIndependentclient] = useState("");
  const [sqftlabel, setSqftlabel] = useState("House Sqft (Approx, Type it in floating point  2.400)");
  const [homeownerlabel, setHomeownerlabel] = useState("Homeowner Name");
  const [homeownerphonelabel, setHomeownerphonelabel] = useState("Homeowner Phone");
  const [homeownermaillabel, setHomeownermaillabel] = useState("Homeowner Email");
  const [show, setShow] = useState(false);

  const handleToDetails =()=>
   {    
    navigate("/info");
   }
   const handleToHome =()=>
   {       
    navigate("/"); 
   }
   const handleClose =()=> 
   {
     setShow(false);
   }

  const [compformerror, setCompformerror] = useState(false);
  const [projownerformerror, setProjownerformerror] = useState(false);
  const [idproj, setIdproj] = useState();
 


  const architecDesignclients = [

    {
      id: 4,
      name: "Diego Valencia",
    },
    {
      id:23,
      name:"SuLing"
    }
    ,
    {
      id:38,
      name:"Oliver Nery"
    }
    ,
    {
      id:39,
      name:"Rex Mclean"
    }
    ,
    {
      id:40,
      name:"Ramin Zohoor"
    }
  ];

  /////////////////////////////////////////////////////////////////////////////////

  const RenderAddress = () => {
    Geocode.setApiKey("AIzaSyBNPVvrMUVEGNXollqj4pQO0czA8RaRGRk");
    Geocode.setLanguage("en");
    Geocode.setRegion("us");
    Geocode.setLocationType("ROOFTOP");
    Geocode.enableDebug();
    Geocode.fromAddress(homeaddress).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setLatitude(lat);
        setLongitude(lng);
        setZipcode(response.results[0].address_components[8].short_name);
        setState(response.results[0].address_components[5].short_name);
        setCity(response.results[0].address_components[3].short_name);
        setResults(response);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const RenderInit = () => {
    Geocode.setApiKey("AIzaSyBNPVvrMUVEGNXollqj4pQO0czA8RaRGRk");
    Geocode.setLanguage("en");
    Geocode.setRegion("us");
    Geocode.setLocationType("ROOFTOP");
    Geocode.enableDebug();
    Geocode.fromAddress(liberty).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setLatitude(lat);
        setLongitude(lng);
        setZipcode(response.results[0].address_components[8].short_name);
        setState(response.results[0].address_components[5].short_name);
        setCity(response.results[0].address_components[3].short_name);
        setResults(response);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  useEffect(() => {
    setHomeaddress(form.address);
    setHousesqft(form.housesqft);
    setHomeownername(form.homeownername);
    setHomeownerphone(form.homeownerphone);
    setHomeownermail(form.homeownermail);
    setProjectdescription(form.description);
    setJobday(form.jobday);
    setTimeday(form.timeday);
  }, [
    form.address,
    form.housesqft,
    form.homeownername,
    form.homeownerphone,
    form.homeownermail,
    form.description,
    form.jobday,
    form.timeday,
  ]);

  useEffect(() => {
    RenderAddress();
  }, [homeaddress]);

  useEffect(() => {
    RenderInit();
  }, []);

  //////////////////////////////////////////////////////////////////////////////////

  const containerStyle = {
    width: "450px",
    height: "370px",
    backgroundColor: "white",
  };

  /////////////////////////////////////////////////////

  const createIndClient = (e) => {
    setIndependentclient(e.target.value);
    setProjectownername(e.target.id);
  };

  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

  //Toggle the checkboxes//
  const handleCheck = (id) => {
    const listItems = checkboxes.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setCheckboxes(listItems);
  };
  //Toggle the checkboxes//

  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

  //Bring the companies//
  useEffect(() => {
    fetch("https://node.vrealism.com:5000/api/companies")
      .then((res) => res.json())
      .then((res) => {
        setCompanies(res);
      });
  }, []);
  //Bring the companies//


  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //


  //Bring the client by company and set company//
/*   const bringClientFunction = (e) => {
    setCompformerror(false);
    setCompanyrequest(e);
    setCompany2client(e);
    const company = e;
    fetch("https://node.vrealism.com:5000/bringClient", {
      method: "POST",
      body: JSON.stringify({
        company,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        const data = res.map((items) => ({
          name: items.cli_name,
          id: items.cli_id,
        }));
        setClients(data);
      });
  }; */
   //Bring the client by company and set company//



   ////////////////////////////////////////////////

   const bringClientFunction = (e) => 
   {

    setCompformerror(false);
    setCompanyrequest(e);
    setCompany2client(e);
    const company = e;

    fetch("https://node.vrealism.com:5000/bringClient",{
      method: "POST",
      body: JSON.stringify({
        company,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        const data = res.map((items) => ({
          name: items.cli_name,
          id: items.cli_id,
        }));

      fetch("https://node.vrealism.com:5000/bringClientsAndIndependents",{
            method: "POST",
            body: JSON.stringify({
              company,
            }),
            headers: { "Content-Type": "application/json" },
          })
          .then((res)=>res.json())
          .then((json)=>{

            const dita = json.map((items) => ({
              name: items.name,
              id: items.client_id,
            }));
            setClients([...data, ...dita]);    
          })       
      });
  };

   /////////////////////////////////////////////////

  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

  const regexNumbers = /^[+-]?([0-9]+([0-9]*)?|[0-9]+)$/;
  const regexAlpha = /^[A-Za-z\s]+$/;
  const regexInteger = /[0-9]/;
  const regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;



  const validateForm = (form) => {

    let errors = {};

   /* Test address */
    if(form.address === "")
    {
      errors.address = "";
    }
    else if(form.address.length >= 55){
      errors.address = "Address field value is too long"; 
    }
    else if(form.address.length === 3 || form.address.length <= 3){
      errors.address = "Address field value is too short"
    }  
    /* Test address */
         /*  */
         /*  */
         /*  */
    /* Test sqft */
    if(form.housesqft === "")
    {
      errors.housesqft = "";
    }
    else if(form.housesqft.length >= 10){
      errors.housesqft = "House sqft field value is to long";
    }
    else if(form.housesqft.length === 3 || form.housesqft.length <= 3){
      errors.housesqft = "House sqft field value is to short";
    }
    else if (regexNumbers.test(form.housesqft.trim())) {
      errors.housesqft = "House sqft field value must be float point";
    }   
    /* Test sqft */
         /*  */
         /*  */
         /*  */
    /* Test Homeowner name */
    if(form.homeownername === ""){
      errors.homeownername = "";
    }
    else if(form.homeownername.length >= 40){
      errors.homeownername = "Home owner name field value is too long"; 
    }
    else if(form.homeownername.length === 3 || form.homeownername.length <= 3){
      errors.homeownername = "Home owner name filed value is too short"
    }
    else if (!regexAlpha.test(form.homeownername.trim())) {
      errors.homeownername = "Home owner name field value must be literal";
    }
    /* Test Homeowner name */
           /*  */
           /*  */
           /*  */
    /* Test Homeowner phone */
    if(form.homeownerphone === ""){
      errors.homeownerphone = "";
    }
    else if(form.homeownerphone.length >= 11){
      errors.homeownerphone = "Home owner phone field value is too long"; 
    }
    else if(form.homeownerphone.length < 10 || form.homeownerphone.length <= 3){
      errors.homeownerphone = "Home owner phone filed value is too short"
    }
    else if (!regexInteger.test(form.homeownerphone.trim())) {
      errors.homeownerphone = "Home owner phone field value must be a numbers";
    }
    /* Test Homeowner phone */
          /*  */
           /*  */
           /*  */
    /* Test Homeowner mail */
    if(form.homeownermail === ""){
      errors.homeownermail = "";
    }
    else if(form.homeownermail.length > 45){
      errors.homeownermail = "Home owner mail field value is too long"; 
    }
    else if(form.homeownermail.length === 3 || form.homeownermail.length <= 3){
      errors.homeownermail = "Home owner mail filed value is too short"
    }
    else if (!regexEmail.test(form.homeownermail.trim())) {
      errors.homeownermail =
        "Home owner mail field value must contains @ and .com or co";
    } 
    /* Test Homeowner mail */

    return errors;
  };


  const handleChance = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };


  const handleBlur = (e) => {
    handleChance(e);
    setErrors(validateForm(form));
  };



  const enviarFetch = () => 
  {


        if (!companyrequest) {
          setCompformerror(true);
          inputCompany.current.focus();
        }
        if (!projectownername) {
          setProjownerformerror(true);
          inputClients.current.focus();
        }else
        {

          fetch("https://node.vrealism.com:5000/create", {
            method: "POST",
            body: JSON.stringify({
              homeaddress,
              homeownername,
              homeownerphone,
              homeownermail,
              housesqft,
              projectownername,
              companyrequest,
              projectdescription,
              currentdate,
              checkboxes,
              independentclient,
              jobday,
              timeday,
            }),
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
            .then((json) => {
              if (json) {               
                /* auth.setProjid(json.insertId); */
                window.localStorage.setItem('projectid', JSON.stringify(json.insertId).replace(/["']/g, ""));                             
                setForm(initialForm);
                inputRef.current.reset();            
                setShow(true);
                auth.setIdcheck(json.insertId);           
              }
            });


         }





            /* if(errors.address)
                {
                  setErrors({...errors,address:"Correct or delete the address field to continue"})      
                }
                else if(errors.housesqft){
                  setErrors({...errors,housesqft:"Correct or delete the house sqft field to continue"})
                }
                else if(errors.homeownername){
                  setErrors({...errors,homeownername:"Correct or delete the homeowner name field to continue"})
                }   */ 

            /*   
                else if(errors.housesqft === undefined)
                {
                  setErrors({...errors,housesqft:"Correct or delete the house sqft field to continue"})
                } */

                /* if(errors.housesqft || errors.housesqft === undefined || errors.housesqft === "" || errors.housesqft === null)
                {
                  setErrors({...errors,housesqft:"Correct or delete the house sqft field to continue"})
                } */




              /*  else{
                  setErrors({...errors,address:""})
                } */
            /* 
                if(errors.housesqft){
                  setErrors({...errors,housesqft:"Correct or delete the house sqft field to continue"})
                }else{
                  setErrors({...errors,housesqft:""})
                } */


              /*  

                if(errors.homeownername){
                  setErrors({...errors,homeownername:"Correct or delete the homeowner name field to continue"})
                }else{
                  setErrors({...errors,homeownername:""})
                }

                if(errors.homeownerphone){
                  setErrors({...errors,homeownerphone:"Correct or delete the homeowner phone field to continue"})
                }else{
                  setErrors({...errors,homeownerphone:""})
                }

                if(errors.homeownermail){
                  setErrors({...errors,homeownermail:"Correct or delete the homeowner mail field to continue"})
                }else{
                  setErrors({...errors,homeownermail:""})
                }
            */

                /*  if(companyrequest
                  &&  projectownername === ""
                  &&  errors.address === ""
                  &&  errors.housesqft === ""
                  &&  errors.homeownername === ""
                  &&  errors.homeownerphone === ""
                  &&  errors.homeownermail){
                  fetch("https://node.vrealism.com:5000/create", {
                    method: "POST",
                    body: JSON.stringify({
                      homeaddress,
                      homeownername,
                      homeownerphone,
                      homeownermail,
                      housesqft,
                      projectownername,
                      companyrequest,
                      projectdescription,
                      currentdate,
                      checkboxes,
                      independentclient,
                      jobday,
                      timeday,
                    }),
                    headers: { "Content-Type": "application/json" },
                  })
                    .then((res) => res.json())
                    .then((json) => {
                      if (json) {
                        setForm(initialForm);
                        inputRef.current.reset();
                        setShow(true);
                      }
                    });
                }else{
                  inputCompany.current.focus();
                } */




              /*  if( companyrequest  &&  projectownername ){

                  fetch("https://node.vrealism.com:5000/create", {
                    method: "POST",
                    body: JSON.stringify({
                      homeaddress,
                      homeownername,
                      homeownerphone,
                      homeownermail,
                      housesqft,
                      projectownername,
                      companyrequest,
                      projectdescription,
                      currentdate,
                      checkboxes,
                      independentclient,
                      jobday,
                      timeday,
                    }),
                    headers: { "Content-Type": "application/json" },
                  })
                    .then((res) => res.json())
                    .then((json) => {
                      if (json) {
                        setForm(initialForm);
                        inputRef.current.reset();
                        setShow(true);
                      }
                    });

                  }else{
                    inputCompany.current.focus();
                  } */

   
  };


  ///Event of form submit///
  const handleSubmit = (e) => 
  {
            e.preventDefault();            
            
            {
              errors.address &&
                setTimeout(() =>
                {
                  setErrors({...errors,address :"Correct or delete the address field to continue"})
                }, 1000);
            }
            {
              errors.housesqft &&
                setTimeout(() => 
                {
                  setErrors({...errors,housesqft :"Correct or delete the house sqft field to continue"})
                }, 2000);
            }
            {
              errors.homeownername &&
                setTimeout(() =>
                 {
                  setErrors({...errors,homeownername:"Correct or delete the homeowner name field to continue"})
                }, 3000);
            }
            {
              errors.homeownerphone &&
                setTimeout(() => {
                  setErrors({...errors,homeownerphone:"Correct or delete the homeowner phone field to continue"})
                }, 4000);
            }
            {
              errors.homeownermail &&
                setTimeout(() => {
                  setErrors({...errors,homeownermail:"Correct or delete the homeowner mail field to continue"})
                }, 5000);
            }

         const areTrue = Object.values(errors).every((value)=>value === "")

         if(areTrue){
          enviarFetch();
         }else{
          console.log("esecu")
         }    

   };
  ///Event of form submit///

  //*********************************************************** //
  ////////////////////SEPARATOR///////////////////////////////////
  //*********************************************************** //
  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
      }}
    >
        <Form.Group
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
            <Form.Group
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "",
              }}
            >
              <p
                className="built"
                style={{
                  color: "gray",
                  fontSize: "35px",
                  fontWeight: "bold",
                }}
              >
                As built service checklist
              </p>
            </Form.Group>

            <Form.Group
              style={{ marginBottom: "10px", color: "gray", marginTop: "" }}
            >
              <small>
                <FiberManualRecordIcon
                  style={{ fontSize: "10px", color: "orange", marginRight: "5px" }}
                />
                Submit your request{" "}
              </small>
            </Form.Group>

            <Form onSubmit={handleSubmit} ref={inputRef}>

              <Form.Group className="mb-1" controlId="formBasicNamel">
                <FloatingLabel
                  controlId="floatingInput"
                  label="House Address"
                  className="mb-1"
                >
                  <Form.Control
                    style={{
                      width: "500px",
                      textAlign: "left",
                    }}
                    type="text"
                    onChange={(e) => handleChance(e)}
                    onBlur={(e) => handleBlur(e)}
                    value={form.address}
                    name="address"
                  />
                </FloatingLabel>
                {errors.address && (
                  <p
                    style={{
                      color: "pink",
                      position: "absolute",
                      marginTop: "-45px",
                      marginLeft: "370px",
                      fontSize: "10px",
                      backgroundColor: "black",
                      width: "calc(auto + 15px)",
                      padding: "5px",
                      borderRadius: "5px",
                      zIndex: "9999",
                    }}
                  >
                    {errors.address}
                  </p>
                )}
              </Form.Group>

              <Form.Group className="mb-1" controlId="formBasicNamel">
                <FloatingLabel
                  controlId="floatingInput"
                  label={sqftlabel}
                  className="mb-1"
                >
                  <Form.Control
                    style={{ width: "500px" }}
                    type="text"
                    onChange={(e) => handleChance(e)}
                    onBlur={(e) => handleBlur(e)}
                    value={form.housesqft}
                    name="housesqft"
                  />
                </FloatingLabel>
                {errors.housesqft && (
                  <p
                    style={{
                      color: "yellow",
                      position: "absolute",
                      marginTop: "-45px",
                      marginLeft: "370px",
                      fontSize: "10px",
                      backgroundColor: "black",
                      width: "calc(auto + 15px)",
                      padding: "5px",
                      borderRadius: "5px",
                      zIndex: "9999",
                    }}
                  >
                    {errors.housesqft}
                  </p>
                )}
              </Form.Group>

              <Form.Group className="mb-1" controlId="formBasicNamel">
                <FloatingLabel
                  controlId="floatingInput"
                  label={homeownerlabel}
                  className="mb-1"
                >
                  <Form.Control
                    style={{ width: "500px" }}
                    type="text"
                    onChange={(e) => handleChance(e)}
                    onBlur={(e) => handleBlur(e)}
                    value={form.homeownername}
                    name="homeownername"
                  />
                </FloatingLabel>
                {errors.homeownername && (
                  <p
                    style={{
                      color: "magenta",
                      position: "absolute",
                      marginTop: "-45px",
                      marginLeft: "370px",
                      fontSize: "10px",
                      backgroundColor: "black",
                      width: "calc(auto + 15px)",
                      padding: "5px",
                      borderRadius: "5px",
                      zIndex: "9999",
                    }}
                  >
                    {errors.homeownername}
                  </p>
                )}
              </Form.Group>

              <Form.Group className="mb-1" controlId="formBasicNamel">
                <FloatingLabel
                  controlId="floatingInput"
                  label={homeownerphonelabel}
                  className="mb-1"
                >
                  <Form.Control
                    style={{ width: "500px" }}
                    type="text"
                    onChange={(e) => handleChance(e)}
                    onBlur={(e) => handleBlur(e)}
                    value={form.homeownerphone}
                    name="homeownerphone"
                  />
                </FloatingLabel>
                {errors.homeownerphone && (
                  <p
                    style={{
                      color: "aqua",
                      position: "absolute",
                      marginTop: "-45px",
                      marginLeft: "370px",
                      fontSize: "10px",
                      backgroundColor: "black",
                      width: "calc(auto + 15px)",
                      padding: "5px",
                      borderRadius: "5px",
                      zIndex: "9999",
                    }}
                  >
                    {errors.homeownerphone}
                  </p>
                )}
              </Form.Group>

              <Form.Group className="mb-1" controlId="formBasicNamel">
                <FloatingLabel
                  controlId="floatingInput"
                  label={homeownermaillabel}
                  className="mb-1"
                >
                  <Form.Control
                    style={{ width: "500px" }}
                    type="text"
                    onChange={(e) => handleChance(e)}
                    onBlur={(e) => handleBlur(e)}
                    value={form.homeownermail}
                    name="homeownermail"
                  />
                </FloatingLabel>
                {errors.homeownermail && (
                  <p
                    style={{
                      color: "white",
                      position: "absolute",
                      marginTop: "-45px",
                      marginLeft: "370px",
                      fontSize: "10px",
                      backgroundColor: "black",
                      width: "calc(auto + 15px)",
                      padding: "5px",
                      borderRadius: "5px",
                      zIndex: "9999",
                    }}
                  >
                    {errors.homeownermail}
                  </p>
                )}
              </Form.Group>

               {/*//*********************************************************** //
                /////////////////SEPARATOR//////////////////////////////////////
                //*********************************************************** //*/}
               {/* Bring  Companies */}               
              <Form.Group className="mb-1" controlId="formBasicNamel">
                <Form.Select
                  ref={inputCompany}
                  style={{ width: "500px" }}
                  aria-label="Default select example"
                  onChange={(e) => bringClientFunction(e.target.value)}
                  >
                    {compformerror?
                    <option style={{color:'red'}}>Select the company is required !!!</option>
                    :
                    <option>Company requesting service</option>}
                    {companies.map((element) => (
                      <option value={element.com_id} key={element.com_id} name="companyfieldrequest">
                        {element.com_name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
              {/* Bring  Companies */}
              {/*//*********************************************************** //
              /////////////////SEPARATOR//////////////////////////////////////
              //*********************************************************** //*/}
              {/* Bring Clients by Company */}
              <Form.Group className="mb-1" controlId="formBasicNamel">
                    <Form.Select
                        ref={inputClients}
                        style={{ width: "500px" }}
                        aria-label="Default select example"
                        onChange={(e) =>{
                          setProjownerformerror(false);
                          setProjectownername(e.target.value)
                        } }
                        >

                        {projownerformerror
                        ?
                        <option style={{ color: "#aeb1b4" }}>
                          Choose a project owner is required !!!
                        </option>
                        :
                        <option style={{ color: "#aeb1b4" }}>
                          Choose a project owner
                        </option>}
                        {clients.map((el) => (
                        <option value={el.id} key={el.id}>
                          {el.name}
                        </option>
                        ))}
                     </Form.Select> 
          
                        {/* {company2client === "17"
                          ?           
                            <FloatingLabel label="Project owner name" className="mb-1">
                              <Form.Control
                                id={Date.now()}
                                style={{ width: "500px" }}
                                type="text"
                                onChange={(e) => createIndClient(e)}
                              />
                            </FloatingLabel>           
                      
                          :          
                      
                            <Form.Select
                            ref={inputClients}
                            style={{ width: "500px" }}
                            aria-label="Default select example"
                            onChange={(e) =>{
                              setProjownerformerror(false);
                              setProjectownername(e.target.value)
                            } }
                          >

                            {projownerformerror
                            ?
                            <option style={{ color: "#aeb1b4" }}>
                              Choose a project owner is required !!!
                            </option>
                            :
                            <option style={{ color: "#aeb1b4" }}>
                              Choose a project owner
                            </option>}
                            {clients.map((el) => (
                            <option value={el.id} key={el.id}>
                              {el.name}
                            </option>
                            ))}
                          </Form.Select>} 
                       */}

              </Form.Group>
              {/* Bring Clients by Company */}
              {/*//*********************************************************** //
              /////////////////SEPARATOR//////////////////////////////////////
              //*********************************************************** //*/}
              <Form.Group className="mb-1 mt-2" controlId="formBasicNamel">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Input project´s description"
                  className="mb-1"
                >
                  <Form.Control
                    style={{ width: "500px" }}
                    as="textarea"
                    aria-label="With textarea"
                  /*  value={projectdescription} */
                  /*  onChange={(e) => setProjectdescription(e.target.value)} */
                  value={form.description}
                  onChange={(e) => handleChance(e)}
                  onBlur={(e) => handleBlur(e)}
                  name="description"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group
                style={{
                  marginTop: "20px",
                  color: "gray",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                <p>Type of services</p>
              </Form.Group>
              <Form.Group
                className="mt-3"
                style={{ display: "flex", width: "500px" }}
              >
                {checkboxes.map((item) => (
                  <Form.Check
                    style={{
                      fontSize: "12px",
                      padding: "18px",
                      marginTop: "-20px",
                      marginLeft: "5px",
                    }}
                    key={item.id}
                    type="checkbox"
                    label={item.item}
                    checked={item.checked}
                    value={item.item}
                    onChange={() => handleCheck(item.id)}
                    id={item.item}
                  />
                ))}
              </Form.Group>
              <Form.Group style={{height:'10px'}}>
              </Form.Group>

              <Form.Group style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>              
                  <Form.Group style={{display:'flex', flexDirection:'column'}}>
                      <Form.Group
                        style={{
                          marginTop: "10px",
                          color: "gray",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                      <p>Job Day</p>
                      </Form.Group>
                      <Form.Group>
                      <Form.Control
                        style={{
                        width: "200px",
                        marginLeft: "1px",
                        marginTop: "1px",
                        }}
                        type="date"
                      /*  value={jobday} */
                      /*   value={form.jobday}
                        onChange={(e) => {
                        setJobday(e.target.value);
                        }} */
                        value={form.jobday}
                        onChange={(e) => handleChance(e)}
                        onBlur={(e) => handleBlur(e)}
                        name="jobday"
                        
                        />
                      </Form.Group>
                  </Form.Group>
                  <Form.Group style={{display:'flex', flexDirection:'column'}}>
                      <Form.Group
                        style={{
                          marginTop: "10px",
                          color: "gray",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        <p>Time</p>
                      </Form.Group>
                      <Form.Group>
                      <Form.Control
                      style={{
                      width: "200px",
                      marginLeft: "1px",
                      marginTop: "1px",
                      }}
                      type="time"
                    /*   value={timeday}
                      onChange={(e) => {
                      setTimeday(e.target.value);
                      }} */
                      value={form.timeday}
                      onChange={(e) => handleChance(e)}
                      onBlur={(e) => handleBlur(e)}
                      name="timeday"                 
                      />
                      </Form.Group>
                  </Form.Group>
              </Form.Group>

              <Button
                variant="danger"
                type="submit"
                className="mt-20 buttonhover"
                style={{ marginTop: "30px", width: "100px" }}
              >
                Submit
              </Button>

              <Form.Group style={{ height: "50px" }}></Form.Group>
            </Form>

        </Form.Group>
      
        <Form.Group
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Form.Group style={{ marginTop: "105px", marginLeft: "20px" }}>
            {" "}
            {results && (
              <Form.Group>
                <LoadScript googleMapsApiKey="AIzaSyBNPVvrMUVEGNXollqj4pQO0czA8RaRGRk">
                  <GoogleMap
                    mapTypeId="satellite"
                    zoom={18}
                    mapContainerStyle={containerStyle}
                    center={{ lat: latitude, lng: longitude }}
                  >
                    <Marker position={{ lat: latitude, lng: longitude }} />
                  </GoogleMap>
                </LoadScript>
              </Form.Group>
            )}
          </Form.Group>

          <Form.Group style={{ marginLeft: "20px" }}>
            <Table striped bordered hover responsive>
              <thead>
                {" "}
                <tr style={{ textAlign: "center" }}>
                  <th>State</th>
                  <th>City</th>
                  <th>Zipcode</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: "center" }}>
                  <td> {state}</td>
                  <td>{city}</td>
                  <td>{zipcode}</td>
                </tr>
              </tbody>
            </Table>
          </Form.Group>
          
        </Form.Group>
        
        
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <AssignmentTurnedInOutlinedIcon
                style={{
                  fontSize: "40px",
                  marginRight: "5px",
                  color: "orange",
                }}
              />
              Project created succesfully !
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p className="popap" style={{ marginBottom: "-10px", display:"flex" , width:'350px' , alignItems:"center" }}>
            <InfoIcon
              style={{
                padding: "6px",
                color: "orange",
                marginTop:'-30px'
              }}
            />
            {auth.client}, You can explorer on the Details section.
              <Button variant="outline-primary" onClick={handleToDetails} style={{marginLeft:'20px'}}> 
                  Details
              </Button>            
            </p>


            <p className="popap" style={{ marginBottom: "-10px", display:"flex", width:'350px', marginTop:'40px' , alignItems:"center"}}>
            <InfoIcon
              style={{
                padding: "6px",
                color: "orange",
                marginTop:'-30px'
              }}
            /> Go to explore Home section.
              <Button variant="outline-primary" onClick={handleToHome} style={{marginLeft:'20px'}}>
                  Home
              </Button>            
            </p>
            
            <p className="popap" style={{ marginBottom: "-10px", display:"flex", width:'350px', marginTop:'40px' , alignItems:"center"}}>
            <InfoIcon
              style={{
                padding: "6px",
                color: "orange",
                marginTop:'-30px'
              }}
            /> Continue on create Project section.
              <Button variant="outline-primary" onClick={handleClose} style={{marginLeft:'20px'}}>
                  Close
              </Button>            
            </p>
            <p style={{marginTop:'20px'}}></p>
          </Modal.Body>
          
        </Modal>


    </Container>
  );
};

export default MakeProject;
