import { useState } from 'react';
import './sidebar.css';
import useAuth from '../../auth/useAuth';
import {
  LineStyle,
  Timeline,
  TrendingUp,
  PermIdentity,
  Storefront,
  AttachMoney,
  BarChart,
  MailOutline,
  DynamicFeed,
  ChatBubbleOutline,
  WorkOutline,
  Report,
  CloudDownload,
} from '@material-ui/icons';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import { Link } from 'react-router-dom';
import { Button, Dropdown } from 'react-bootstrap';

export default function Sidebar() {
  const auth = useAuth();
  auth.setUser(window.localStorage.getItem('username'));
  const [project, setProject] = useState(false);
  const [searching, setSearching] = useState(false);
  const [home, setHome] = useState(false);
  return (
    <div style={{ height: 'auto' }}>
      {auth.user ? (
        <div className="sidebar">
          <div className="sidebarWrapper">
            <div className="sidebarMenu">
              <ul className="sidebarList">
                <Link to="/" className="link">
                  <li className="sidebarListItem">
                    <HomeOutlinedIcon
                      style={{ fontSize: '40px' }}
                      onClick={() => {
                        /* auth.setIdcheck(auth.projid); */
                        auth.setIdcheck(
                          parseInt(window.localStorage.getItem('projectid')),
                        );
                        auth.setSearchi(false);
                      }}
                      onMouseEnter={() => setHome(true)}
                      onMouseLeave={() => setHome(false)}
                    />
                    {home && (
                      <small
                        style={{ fontWeight: 'bold', marginTop: '3px' }}
                        className="beauty"
                      >
                        <Button variant="primary">Dashboard</Button>
                      </small>
                    )}
                  </li>
                </Link>
              </ul>
            </div>
            <div className="sidebarMenu">
              <ul className="sidebarList">
                <Link
                  /* to="/#search" */ to={{
                    pathname: '/',
                    /*  search: "?sort=search", */
                    hash: '#search',
                    /*  state: { fromDashboard: true }, */
                  }}
                  className="link"
                >
                  <li
                    className="sidebarListItem"
                    onClick={() => auth.setSearchi(true)}
                  >
                    <ContentPasteSearchOutlinedIcon
                      style={{ fontSize: '40px' }}
                      onClick={() => auth.setIdcheck(auth.projid)}
                      onMouseEnter={() => setSearching(true)}
                      onMouseLeave={() => setSearching(false)}
                    />
                    {searching && (
                      <small
                        style={{ marginLeft: '5px', fontWeight: 'bold' }}
                        className="beauty"
                      >
                        <Button variant="success">Search project</Button>
                      </small>
                    )}
                  </li>
                </Link>
              </ul>
            </div>
            <div className="sidebarMenu">
              <ul className="sidebarList">
                <Link to="/makeproject" className="link">
                  <li className="sidebarListItem">
                    <FactCheckOutlinedIcon
                      style={{ fontSize: '40px' }}
                      onMouseEnter={() => setProject(true)}
                      onMouseLeave={() => setProject(false)}
                    />
                    {project && (
                      <small
                        style={{ marginLeft: '5px', fontWeight: 'bold' }}
                        className="beauty"
                      >
                        <Button variant="danger">Create project</Button>
                      </small>
                    )}
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
