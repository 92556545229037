import './searchAdmin.css';
import { useRef, useEffect } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import useAuth from '../../auth/useAuth';
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  FormControl,
  Nav,
  NavDropdown,
  Navbar,
} from 'react-bootstrap';

export default function SearchAdmin() {
  return (
    <Container className="searchForm" onSubmit={(e) => e.preventDefault()}>
      <Form className="d-flex">
        <Navbar>
          <FormControl
            placeholder="By name"
            id="search"
            type="search"
            role="searchbox"
            style={{ width: '500px' }}
          />

          <Button
            variant="outline-primary"
            type="submit"
            style={{
              marginLeft: '5px',
            }}
          >
            Search
          </Button>
        </Navbar>
      </Form>
    </Container>
  );
}
