import React from 'react'
import './Espacio_h10px.css'


const Espacio_h10px = ()=>{
  return(
    <div className="h10px"></div>
  )
}

export default Espacio_h10px