import "./details.css";
import { gapi } from 'gapi-script';
import { Link } from "react-router-dom";
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import StorageIcon from '@mui/icons-material/Storage';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import EditIcon from "@mui/icons-material/Edit";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import InfoIcon from "@mui/icons-material/Info";
import {
  Audio,
  BallTriangle,
  ThreeDots,
  TailSpin,
  Rings,
  Puff,
  Oval,
  Hearts,
  Grid,
  Circles,
  Bars,
} from "react-loader-spinner";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Auth2 from "../auth2/Auth2";
import useAuth from "../../auth/useAuth";
import {
  Container,
  Form,
  Button,
  FloatingLabel,
  FormGroup,
  Label,
  Nav,
  Badge,
  Row,
  Col,
  Table,
  Accordion,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import TextArea from "./TextArea";

const Details = () => {
  const inputRefPdf = useRef();
  const [listDocumentsVisible, setListDocumentsVisibility] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [isLoadingGoogleDriveApi, setIsLoadingGoogleDriveApi] = useState(false);
  const [isFetchingGoogleDriveFiles, setIsFetchingGoogleDriveFiles] = useState(false);
  const [signedInUser, setSignedInUser] = useState();
  const handleChange = (file) => {};
  const [googledrive, setGoogledrive] = useState("");
  const auth = useAuth();
  const navigate = useNavigate();
  /* const [idproj, setIdproj] = useState(auth.projid); */
  const [idproj, setIdproj] = useState(parseInt(window.localStorage.getItem('projectid')));
  const [form, setForm] = useState([]);
  const [clientcliid, setClientcliid] = useState("");
  const [homeownername, setHomeownername] = useState("");
  const [homeaddress, setHomeaddress] = useState("");
  const [sqft, setSqft] = useState("");
  const [projdatecreate, setProjectdatecreate] = useState("");
  const [contractor, setContractor] = useState({});
  const [projectowner, setProjectowner] = useState("")
  const [company, setCompany] = useState("");
  const [status, setStatus] = useState("");
  const [comments, setComments] = useState("");
  const [services, setServices] = useState([]);
  const [servicestable, setServicestable] = useState([]);
  const [surveyor, setSurveyor] = useState("");
  const [drafter, setDrafter] = useState("");
  const [details, setDetails] = useState("");
  const [note, setNote] = useState("");
  const [iframecontainer, setIframecontainer] = useState([]);
  const [iframecontainertwo, setIframecontainertwo] = useState([]);
  const [downloadcontainer, setDownloadcontainer] = useState([]);
  const [waveappid, setWaveappid] = useState("");
  const [waveappstatus, setWaveappstatus] = useState("");
  const [linkwaveapp, setLinkwaveapp] = useState("");
  const [loading, setLoading] = useState(false);
  const [pdfloading, setPdfloading] = useState(false);
  const [displayiframe, setDisplayiframe] = useState("none");
  const [displayiframetwo, setDisplayiframetwo] = useState("none");
  const [iframelink, setIframelink] = useState("");
  const [iframelinktwo, setIframelinktwo] = useState("");
  const [projectfolders, setProjectfolders] = useState([]);


const clients = [
{id:1,name:"admin",},
{id:2,name:"Joseph",},
{id:3,name:"Vrealism",},
{id:4,name:"Diego Valencia",},
{id:5,name:"Amit",},
{id:6,name:"Karen",},
{id:7,name:"Liane",},
{id:8,name:"Sunny",},
{id:9,name:"Alejandro Vargas",},
{id:10,name:"Eli",},
{id:11,name:"tester",},
{id:12,name:"clientsteven",},
{id:13,name:"Miguel",},
{id:14,name:"Ruben",},
{id:15,name:"Catalina",},
{id:16,name:"Laura",},
{id:17,name:"Juan",},
{id:18,name:"Edwin",},
{id:19,name:"abs",},
{id:20,name:"Alejandro Vargas",},
{id:21,name:"aswdd",},
{id:22,name:"eperez",},
{id:23,name:"Sueling",},
{id:24,name:"dfre",},
{id:25,name:"dferf",},
{id:26,name:"Bay Remodeling",},
{id:27,name:"InnovoBuilders",},
{id:28,name:"Liane Carter Interiors",},
{id:29,name:"Tomio",},
{id:30,name:"Daniel Fonseca",},
{id:31,name:"Ben Megidesh", },
{id:32,name:"Velvet Home Staging",},
{id:33,name:"Tiffany",},
{id:34,name:"Sunny",},
{id:36,name:"Tal Buchnick",},
{id:38,name:"Oliver Nery",},
{id:39,name:"Rex Mclean",},
{id:40,name:"Zamin Zohoor",},
{id:41,name:"Ramin Zohoor",},
{id:42,name:"Tiffany Huang",},
{id:43,name:"Michael Spehar",},
{id:44,name:"Leo Ben Atar",},
{id:45,name:"Avi",},
{id:46,name:"Sal Taraboulsi",},
{id:47,name:"Aviv Shumel",},
{id:48,name:"Yehuda",},
{id:49,name:"Michael",},
{id:50,name:"Alvin G Rivera",},
{id:51,name:"Ammar Mukadam",},
{id:54,name:"Greg Komar",},
{id:55,name:"Manjing Xie",},
{id:56,name:"Jania Sawyer",},
{id:57,name:"info@homebytom",},
{id:58,name:"Mose",},
{id:59,name:"Mona Gilardi",},
{id:60,name:"Julian Barak",},
{id:61,name:"Karen Lok",},
{id:62,name:"David Gavy",},
{id:63,name:"Oscar",},
{id:64,name:"Talor Shlomo",},
{id:65,name:"Briand",},
{id:66,name:"Tal",},
{id:67,name:"Sunny Gao",},
{id:68,name:"Ofir Ayash",},
]

  const initialChat = [
    {
      id: null,
      client: "",
      sms: "",
    },
  ];
  const [chats, setChats] = useState(initialChat);
  const [currentclient, setCurrentclient] = useState(parseInt(window.localStorage.getItem('user_client_id')));
  const companiestosingleobject = [
    "GMBC",
    "Wise Builders",
    "Diego Valencia",
    "Bay Remodeling",
    "InnovoBuilders",
    "Liane Carter Interiors",
    "Nuvision Construction",
    "Oasis Home Design",
    "Oro Coast Builders",
    "Velvet Home Staging",
    "Tiffany",
    "Sunny",
    "Sueling",
    "Karen",
    "Mr & Mrs. Construction & Remodeling",
    "Deco Builders",
    "Independent",
    "Architec or Home Designer",
    "Vrealism",
    "A.O Positive Solutions",
    "Arcus Architecture",
    "ATC Design Center",
    "Baron Constructions",
    "BROTHER AND BROTHER BUILDERS",
    "CA Bear Constructions",
    "DelSur Construction",
    "Direct Home Remodeling Inc",
    "Edri Construction ",
    "Future Vision Remodeling",
    "Han DesignStudio",
    "Home by Tom",
    "Homeowners",
    "Lecut Construction",
    "Nova Builders",
    "On Design & Remodeling",
    "Prime Renovation Homes",
    "QA Buillders",
    "Re-Model Design & Build Co",
    "Realty Builders",
    "Skytech Builders",
    "The Building Company",
    "Wealthentech Inc",
  ];
  const clientosinglearray =  [
    "admin",
    "Joseph",
    "Vrealism",
    "Diego Valencia",
    "Amit",
    "Karen",
    "Liane",
    "Sunny",
    "Alejandro Vargas",
    "Eli",
    "tester",
    "clientsteven",
    "Miguel",
    "Ruben",
    "Catalina",
    "Laura", 
    "Juan", 
    "Edwin",
    "abs",
    "Alejandro Vargas", 
    "aswdd",
    "eperez", 
    "Sueling",
    "dfre",
    "dferf",
    "Bay Remodeling",
    "InnovoBuilders",
    "Liane Carter Interiors",
    "Tomio",
    "Daniel Fonseca", 
    "Ben Megidesh",
    "Velvet Home Staging", 
    "Tiffany",
    "Sunny",
    "Mr. & Mrs",
    "Tal Buchnick",
    "Architec or Home Designer",
    "Oliver Nery",
    "Rex Mclean",
    "Zamin Zohoor", 
    "Ramin Zohoor",
    "Tiffany Huang",
    "Michael Spehar",
    "Leo Ben Atar",
    "Avi",
    "Sal Taraboulsi",
    "Aviv Shumel",
    "Yehuda",
    "Michael",
    "Alvin G Rivera",
    "Ammar Mukadam",
    "Greg Komar",
    "Manjing Xie",
    "Greg Komar",
    "Manjing Xie",
    "Jania Sawyer",
    "info@homebytom",
    "Mose",
    "Mona Gilardi",
    "Julian Barak",
    "Karen Lok",
    "David Gavy",
    "Oscar",
    "Talor Shlomo",
    "Briand", "Tal",
    "Sunny Gao",
    "Ofir Ayash", 
  ];

  const [activekeyvaluematter, setActivekeyvaluematter] = useState(0);
  const [activekeymatter, setActivekeymatter] = useState(1);
  const [activekeyvaluedrone, setActivekeyvaluedrone] = useState(0);
  const [activekeydrone, setActivekeydrone] = useState(1);
  const [activekeyvaluepoly, setActivekeyvaluepoly] = useState(0);
  const [activekeypoly, setActivekeypoly] = useState(1);


   


  /******************* USEEFFECTS *************************************************/

  //Bring all  proj´s table by projid//
  useEffect(() => {
    setLoading(true);
    fetch("https://node.vrealism.com:5000/api/getprojbyid", {
      method: "POST",
      body: JSON.stringify({
        idproj,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        setClientcliid(res[0].client_cli_id);
        setForm(res);
        setClientcliid(res[0].client_cli_id);
        setHomeaddress(res[0].proj_address);
        if (res[0].surveyor.length > 0) {
          setSurveyor(res[0].surveyor);
        } else {
          setSurveyor("Remenber");
        }
        if (res[0].surveyor.length > 0) {
          setDrafter(res[0].architec);
        } else {
          setDrafter("Remenber");
        }
        setSqft(res[0].squarefeet);
        const dateback = res[0].proj_dateCreated;
        const dateformat = new Date(dateback);
        const utc = new Intl.DateTimeFormat("en-US", {
          timeZone: "UTC",
          dateStyle: "full",
        }).format(dateformat);
        setProjectdatecreate(utc);
        setHomeownername(res[0].home_owner_name);
        setDetails(res[0].proj_description);
        setCompany(companiestosingleobject[res[0].proj_houseOwnerName - 1]);
        setProjectowner(clientosinglearray[res[0].client_cli_id -1]);
        setStatus(res[0].proj_status);
        const clientid = res[0].client_cli_id;
        fetch("https://node.vrealism.com:5000/api/clientnamebyid", {
          method: "POST",
          body: JSON.stringify({
            clientid,
          }),
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
          .then((json) => {
            setContractor(json[0].cli_name);
            setLoading(false);
          });
      });
  }, [idproj]);
  //Bring all  proj´s table by projid//

  //Bring all chats by projid//
  useEffect(() => {
    fetch("https://node.vrealism.com:5000/api/getsms", {
      method: "POST",
      body: JSON.stringify({
        idproj,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        const data = res.map((items) => ({
          id: items.id,
          client: clients.find((el) => items.id_client === el.id),
          sms: items.sms,
        }));
        setChats(data);
      });
  }, [projectowner]);
  //Bring all chats by projid//
  
  //Bring all project_services_table by projid//
  useEffect(() => {
    fetch("https://node.vrealism.com:5000/api/getservbyid", {
      method: "POST",
      body: JSON.stringify({
        idproj,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) => setServices(json));
  }, [details]);
  //Bring all project_services_table by projid//

  //Bring all services table//
  useEffect(() => {
    fetch("https://node.vrealism.com:5000/api/servicestable")
      .then((res) => res.json())
      .then((res) => {
        setServicestable(res);
      });
  }, [status]);
  //Bring all services table//

  //Bring project link folders by projid //
  useEffect(() => {
    fetch("https://node.vrealism.com:5000/api/getprojfilesbyid", {
      method: "POST",
      body: JSON.stringify({
        idproj,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => { 
        setProjectfolders(res);
      });
  }, [company]);
  //Bring project link folders by projid //

  //Bring the invoice information//
  useEffect(() => {
    fetch("https://node.vrealism.com:5000/api/getinvoice", {
      method: "POST",
      body: JSON.stringify({
        idproj,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.length > 0) {
          setWaveappid(res[0].num_invoice);
          setWaveappstatus(res[0].inv_status);
          setLinkwaveapp(res[0].linkapp);
        } else {
          setWaveappid("Pending invoice ");
          setWaveappstatus("Pending generated ");
          setLinkwaveapp("Pending link ");
        }
      });
  }, [services]);
  //Bring the invoice information//

   //getting links to files download zone //
   useEffect(() => {
    const data = projectfolders.filter((elex) => {
      if (
        (elex.projfile_linkType === "floorplan") +
        (elex.projfile_linkType === "roofplan") +
        (elex.projfile_linkType === "siteplan") +
        (elex.projfile_linkType === "elevation")+
        (elex.projfile_linkType === "cad")
      ) {
        return {
          key: elex.projfile_id,
          id: elex.projfile_id,
          linktype: elex.projfile_linkType,
          link: elex.projfile_link,
        };
      }
      if(elex.projfile_linkType === "googledrive"){
        setGoogledrive(elex.projfile_link);
      }
    });
    setDownloadcontainer(data);   
  }, [projectfolders]);
  //getting links to files download zone /

 /******************* USEEFFECTS *************************************************/



 /******************* FUNCTIONS **************************************************/
  //sending sms from chat//
  const submitsms = (e) => {
    e.preventDefault();
    if (note === "") {
      alert("text a sms");
      return false;
    }
    fetch("https://node.vrealism.com:5000/chat/sms", {
      method: "POST",
      body: JSON.stringify({
        idproj,
        note,
        currentclient,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) => {
        const data = json.map((items) => ({
          id: items.id,
          client: clients.find((el) => items.id_client === el.id),
          sms: items.sms,
        }));
        setChats(data);
        setNote("");
      });
  };
  //sending sms from chat//  

  //getting links to iframe zone //
  const iframeContainer = (e) => {
    const data = projectfolders.filter((elex) => {
      if (elex.projfile_linkType === e.target.id) {
        return {
          key: elex.projfile_id,
          id: elex.projfile_id,
          linktype: elex.projfile_linkType,
          link: elex.projfile_link,
        };
      }
    });
    setIframecontainer(data);
  };
  //getting links to iframe zone //

   //getting matterport to iframe zone //
   const iframeMatterport = (matterport) => {
    const data = projectfolders.filter((elex) => {
      if (elex.projfile_linkType === matterport) {
        return {
          key: elex.projfile_id,
          id: elex.projfile_id,
          linktype: elex.projfile_linkType,
          link: elex.projfile_link,
        };
      }
    });
    setIframecontainer(data);
  };
  //getting matterport to iframe zone //

   //getting dronedeploy to iframe zone //
   const iframeDroneDeploy = (dronedeploy) => {
    const data = projectfolders.filter((elex) => {
      if (elex.projfile_linkType === dronedeploy) {
        return {
          key: elex.projfile_id,
          id: elex.projfile_id,
          linktype: elex.projfile_linkType,
          link: elex.projfile_link,
        };
      }
    });
    setIframecontainer(data);
   };
  //getting dronedeploy to iframe zone //

   //getting polycam to iframe zone //
   const iframePolyCam = (polycam) => {
    const data = projectfolders.filter((elex) => {
      if (elex.projfile_linkType === polycam) {
        return {
          key: elex.projfile_id,
          id: elex.projfile_id,
          linktype: elex.projfile_linkType,
          link: elex.projfile_link,
        };
      }
    });
    setIframecontainer(data);
  };
  //getting polycam to iframe zone //

  //getting elevation to iframe zone 2 //
   const iframeElevation = (elevation) => {
      const data = projectfolders.filter((elex) => {
        if (elex.projfile_linkType === elevation) {
          return {
            key: elex.projfile_id,
            id: elex.projfile_id,
            linktype: elex.projfile_linkType,
            link: elex.projfile_link,
          };
        }
      });
      setIframecontainertwo(data);
   };
  //getting elevation to iframe zone  2 //

  //getting floorplan to iframe zone 2 //
   const iframeFloorPlan = (floorplan) => {
      const data = projectfolders.filter((elex) => {
        if (elex.projfile_linkType === floorplan) {
          return {
            key: elex.projfile_id,
            id: elex.projfile_id,
            linktype: elex.projfile_linkType,
            link: elex.projfile_link,
          };
        }
      });
      setIframecontainertwo(data);
   };
  //getting floorplan to iframe zone  2 //

  //getting ar to iframe zone 2 //
    const iframeAr = (ar) => {
      const data = projectfolders.filter((elex) => {
        if (elex.projfile_linkType === ar) {
          return {
            key: elex.projfile_id,
            id: elex.projfile_id,
            linktype: elex.projfile_linkType,
            link: elex.projfile_link,
          };
        }
      });
      setIframecontainertwo(data);
   };
  //getting ar to iframe zone  2 //


  //getting siteplan to siteplan zone 2 //
  const iframeSitePlan = (siteplan) => {
      const data = projectfolders.filter((elex) => {
        if (elex.projfile_linkType === siteplan) {
          return {
            key: elex.projfile_id,
            id: elex.projfile_id,
            linktype: elex.projfile_linkType,
            link: elex.projfile_link,
          };
        }
      });
      setIframecontainertwo(data);
   };
  //getting siteplan to siteplan zone  2 //


  //getting roofplan to siteplan zone 2 //
      const iframeRoofPlan = (roofplan) => {
        const data = projectfolders.filter((elex) => {
          if (elex.projfile_linkType === roofplan) {
            return {
              key: elex.projfile_id,
              id: elex.projfile_id,
              linktype: elex.projfile_linkType,
              link: elex.projfile_link,
            };
          }
        });
        setIframecontainertwo(data);
      };
  //getting roofplan to siteplan zone  2 //


  //getting links to iframe zone2 //
     const iframeContainertwo = (e) => {
        e.preventDefault();
        const data = projectfolders.filter((elex) => {
          if (elex.projfile_linkType === e.target.id) {
            return {
              key: elex.projfile_id,
              id: elex.projfile_id,
              linktype: elex.projfile_linkType,
              link: elex.projfile_link,
            };
          }
        });
        setIframecontainertwo(data);
     };
  //getting links to iframe zone2 //

  //getting  number of files for badget//
    const callbacklenght = (back) => {
        return projectfolders.filter((typex) => typex.projfile_linkType === back).length;
     };
  //getting  number of files for badget//

  //getting  number of files for download badget//
    const callbackdownloadlenght = () => {
        return projectfolders.filter(
          (typex) =>
            /*     typex.projfile_linkType === back && */
            (typex.projfile_linkType === "siteplan") +
            (typex.projfile_linkType === "elevation") +
            (typex.projfile_linkType === "floorplan") +
            (typex.projfile_linkType === "roofplan")+
            (typex.projfile_linkType === "cad")
        ).length;
     };
  //getting  number of files for download badget//
/******************* FUNCTIONS **************************************************/



/* °°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°° */


  function searchCompany(suling){
  /* console.log(suling) */
  gapi.client.drive.files.list({
  pageSize:500,
  fields:'nextPageToken, files(id, name, mimeType, modifiedTime)',
  /*q:'name=\'ATC Design Center\'',*/
  q:`'${suling}' in parents`
  /*q:'name=\'Su-Ling\'',*/
  /* q:`id=\'${suling}\'`, */
  /*  q:`id=\'${suling}\'`,  */
  })
  .then((rere)=>{
  /* selectFolder(rere.result.files[0].id) */
  /*  selectFolder(rere.result.files[0])  */
  console.log(rere);
  /* selectPdf(rere, 'VR_1364_SPOONBILL_WAY_SUNNYVALE - ELEVATIONS.pdf') */
 /*  renderPdf(rere); */
  })
  
  //console.log(suling)
  }
  /* 4 */
  const renderPdf = (ri)=>{
  const linki = ri.result.files[0].webViewLink ;
  window.open(linki, "_blank");

}
   /* 3 */
   const findDrive =(folder,link)=>{
      gapi.client.drive.files.list({
        pageSize:500,
        fields:'nextPageToken, files(id, name, mimeType, modifiedTime, webContentLink, webViewLink)',
        /* q:`'${folder}' in parents` */
      }).then((ress)=>{
        gapi.client.drive.files.list({
        pageSize:500,
        fields:'nextPageToken, files(id, name, mimeType, modifiedTime, webContentLink, webViewLink)',
        q:`name=\'${link}\'`,
       }).then((ri)=>{
           renderPdf(ri);
       })
      })
    }
    /* 2 */
   const initClient = (link)=>{
      gapi.client.init({
          'apiKey': 'AIzaSyBNPVvrMUVEGNXollqj4pQO0czA8RaRGRk',
      }).then(function ()
      {       
        gapi.client.load('drive','v3',()=>findDrive(googledrive,link));
      });
    }
    /* 1 */
   const handleClientLoad =(e, link)=>{
      e.preventDefault(); 
      gapi.load('client:auth2', ()=>initClient(link)); 
   }

 /* WORKS FINE TO OPEN LINK */
  /*   const handleBlankPage =(e, link)=>{
    e.preventDefault()
    window.open(link, "_blank");
   } */


   const handleBlankPage =(e, drivelink)=>{
    e.preventDefault();
   
    setPdfloading(true);
    
    const drive = "https://drive.google.com/uc?export=download&id=";
    const link = drivelink.slice(32);
    const linki = link.slice(0,-17);
    const finalink = drive + linki;
    const nodeVrealism = "https://node.vrealism.com/pdfs/";

    fetch("https://node.vrealism.com:5000/api/download/pdf",{
      method: "POST",
      body: JSON.stringify({
        finalink,
        linki,
      }),
      headers: { "Content-Type": "application/json" },
    })
    .then((res)=>{

      setTimeout(()=>{

        setIframelinktwo(nodeVrealism +`${linki}.pdf`);
        setDisplayiframetwo("block");
        setPdfloading(false);

      },2000)

     
    })
    
   }
   /*0*/
  
  
/* °°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°° */




/* °°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°° */
/* 4 */
const downloadPdf = (nat)=>{
  const linki = nat.result.files[0].webContentLink;
  /* console.log(linki);
  window.location.replace(linki) */
  /* navigate(linki) */
  window.open(linki, "_blank");
}
/* 3 */
const findDriveDownload = (projfilelink)=>{
  gapi.client.drive.files.list({
    pageSize:500,
    fields:'nextPageToken, files(id, name, mimeType, modifiedTime, webContentLink, webViewLink)',
  }).then((ress)=>{
    gapi.client.drive.files.list({
    pageSize:500,
    fields:'nextPageToken, files(id, name, mimeType, modifiedTime, webContentLink, webViewLink)',
    q:`name=\'${projfilelink}\'`,
   }).then((ri)=>{
       downloadPdf(ri);
   })
  })
}
/* 2 */
const initDownClient = (projfilelink)=>{
gapi.client.init({
    'apiKey': 'AIzaSyBNPVvrMUVEGNXollqj4pQO0czA8RaRGRk',
}).then(function ()
{       
gapi.client.load('drive','v3',()=>findDriveDownload(projfilelink));
});
}
 /* 1 */
const handleDownloadClient = (e, projfilelink) =>{
  e.preventDefault();
  gapi.load('client:auth2', ()=>initDownClient(projfilelink))
}

const GenerateDownloadLink = (projfilelink)=>
{
  const drive = "https://drive.google.com/uc?export=download&id=";
  const link = projfilelink.slice(32);
  const linki = link.slice(0,-17);
  const finalink = drive + linki;
  window.open(finalink, "_blank");
}
/*0*/



/* useEffect(()=>{
  fetch("https://node.vrealism.com:5000/api/allclients")
  .then((res)=>res.json())
  .then((json)=>console.log(json))
},[]) */








/* °°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°° */
  return (
    <Container style={{width:'auto'}}>       
        <Container style={{
                   backgroundColor:'white', 
                   borderRadius:'20px',
                   marginTop:'20px',
          }}>                
                 
                 
            <Form.Group style={{display:'flex',marginTop:'10px'}}>
                          
                     <p style={{color: "gray",fontSize: "30px",fontWeight: "bold",marginLeft:'50px',marginTop:'40px'}}>
                                Project details
                                <InfoIcon style={{padding:"4px",color:"orange",marginLeft:"10px",marginTop: "6px"}}/>
                                <small style={{ fontSize: "10px"}}>
                                Check the project current details
                                </small>
                     </p>       


                    <Form.Group 
                        style={{
                            zIndex:'9999',
                            backgroundColor:'#008088',
                            borderRadius:'25px',
                            display:'flex',
                            opacity:'0.8',
                            position:'fixed',
                            marginLeft:'67%',
                            marginTop:'40px',
                            padding:'10px', 
                        }}
                    >
                        <Form.Group 
                           style={{display:'flex',justifyContent:'space-around',paddingLeft:'20px',paddingRight:'10px'}}>

                          <Form.Group 
                             style={{display:'flex', flexDirection:'column', alignItems:'center', paddingRight:'20px'}}>
                                <a href="#" title="Edit">                           
                                  <EditIcon  
                                   style={{
                                      fontSize:'30px',
                                      color:'white',
                                     }}
                                     onClick={()=>{
                                     auth.setIdcheck(parseInt(window.localStorage.getItem('projectid'))); 
                                     navigate("/edit");
                                     }}
                                   />
                                </a>
                          </Form.Group>

                          <Form.Group 
                           style={{display:'flex', flexDirection:'column', alignItems:'center' , paddingRight:'20px'}}>
                              <a href="#" title="Return lists">
                                  <ListAltIcon
                                    style={{
                                      fontSize:'30px',
                                      color: "white" ,
                                    }}
                                    onClick={(e) => {
                                     e.preventDefault();
                                     auth.setIdcheck(parseInt(window.localStorage.getItem('projectid')));            
                                     navigate("/");
                                    }}
                                  />
                              </a>
                          </Form.Group>

                          <Form.Group 
                           style={{display:'flex', flexDirection:'column', alignItems:'center' , paddingRight:'10px'}}>
                                                    <a href="" title="Storage">
                                                    <StorageIcon  
                                                     style={{
                                                      fontSize:'30px',
                                                     /*  paddingTop:'5px', */
                                                      color:'white'
                                                      }}
                                                    />
                                                    </a>
                                                   {/*  <a href="" style={{textDecoration:'none' , marginTop:'-5px'}}>                           
                                                    <small style={{color:"white"}}>
                                                    Storage
                                                    </small>
                                                    </a>   */}                            
                          </Form.Group>


                        </Form.Group>
                      
                    </Form.Group>
                        
            </Form.Group>                   

                  

            {/*************************** PROJECT DETAILS **************************/}
            {/* PROJECT DETAILS FIRST ZONE */}
            {/* PROJECT DETAILS INFORMATION NAME ADDRESS DATE STATUS SERVICES CHAT */}
            <Form.Group
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems:'center',
                borderRadius:'10px',
                width:'auto',
              }}
            >
              {loading === true ? (
                <Container
                  style={{
                    width: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid color="orange" height={420} width={80} />
                </Container>
              ) : (
                <div>
                  <Table
                    striped
                    bordered
                    hover
                    responsive
                    style={{width:'auto', marginTop:'30px'}}
                  >
                    <thead>
                      <tr style={{backgroundColor:'rgb(77, 91, 103)', color:'white'}}>
                        <th style={{width:'80px', textAlign:'center'}}>Id</th>
                        <th style={{width:'400px', textAlign:'center'}}>Project</th>
                        <th style={{width:'100px', textAlign:'center'}}>Sqft</th>
                        <th style={{width:'250px', textAlign:'center'}}>Date Created</th>
                        <th style={{width:'150px', textAlign:'center'}}>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{backgroundColor:'white'}}> 
                        <td style={{width:'80px', textAlign:'center'}}>{idproj}</td>
                        <td style={{width:'400px', textAlign:'center'}}>{homeaddress}</td>
                        <td style={{width:'100px', textAlign:'center'}}>{sqft}</td>
                        <td style={{width:'250px', textAlign:'center'}}>{projdatecreate}</td>
                        <td style={{width:'150px', textAlign:'center'}}>{status}</td>
                      </tr>
                    </tbody>
                  </Table>

                  <Table
                    striped
                    bordered
                    hover
                    responsive
                    style={{marginTop:'5px',width:'auto'}}
                  >
                    <thead>
                      <tr style={{backgroundColor:'rgb(77, 91, 103)', color:'white'}}>
                        <th style={{width:'350px', textAlign:'center'}}>Home Owner</th>
                        <th style={{width:'250px', textAlign:'center'}}>Company</th>
                        <th style={{width:'140px', textAlign:'center'}}>Contractor</th>
                        <th style={{width:'120px', textAlign:'center'}}>Surveyor</th>
                        <th style={{width:'120px', textAlign:'center'}}>Drafter</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{backgroundColor:'white'}}>
                        <td style={{width:'350px', textAlign:'center'}}> {homeownername}</td>
                        <td style={{width:'250px', textAlign:'center'}}>{company}</td>
                        <td style={{width:'140px', textAlign:'center'}}>{projectowner}</td>
                        <td style={{width:'120px', textAlign:'center'}}> {surveyor}</td>
                        <td style={{width:'120px', textAlign:'center'}}>{drafter}</td>
                      </tr>
                    </tbody>
                  </Table>

                  <Form.Group 
                   style={{
                    display:'flex', 
                    flexDirection:'row',
                    width:'auto',
                    justifyContent:'space-between',
                    marginTop:'5px',
                    marginBottom:'30px',
                    }}>

                    <Table
                      striped
                      bordered
                      hover
                      responsive
                      style={{width:'auto'}}
                    >
                      <thead>
                        <tr style={{backgroundColor:'rgb(77, 91, 103)', color:'white'}}>
                          <th style={{width:'290px', textAlign:'center'}}> Services</th>
                          <th style={{width:'450px', textAlign:'center'}}> Details</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr style={{backgroundColor:'white'}}>

                          <td style={{width:'250px'}}>
                            <Form.Group
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "10px",
                                height:'180px'
                              }}
                            >
                              <ul
                                style={{
                                  width: "auto",
                                  height: "auto",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignContent: "flex-start",
                                  justifyContent: "flex-start",
                                  alignItems: "flex-start",
                                  alignContent: "flex-start",
                                }}
                              >
                                {servicestable.map((el) =>
                                  services.find(
                                    (item) => item.services_serv_id === el.serv_id
                                  ) ? (
                                    <Form.Check
                                      key={el.serv_id}
                                      style={{ marginLeft: "-30px" }}
                                      type="radio"
                                      checked={true}
                                      label={el.serv_name}
                                      onChange={() => {
                                        return false;
                                      }}
                                    />
                                  ) : null
                                )}
                              </ul>
                            </Form.Group>
                          </td>                 
                        
                          <td style={{width:'450px'}}>
                            <Form.Group style={{padding:'20px', height:'180px'}}>
                              {details}
                            </Form.Group>                  
                          </td>

                         
                        </tr>
                      </tbody>
                    </Table>


                     {/* CHAT ZONE */}

                     {/* DIV CHAT TEST */}
                     <Form.Group 
                        style={{
                        width:'auto',
                        height:'250px',
                        display:'flex',
                        flexDirection:'column',
                        backgroundColor:'gray',
                        borderRadius:'25px',
                        }}
                     >
                       <Form.Group style={{display:'flex', justifyContent:'center'}}>
                         <a href="#" style={{textDecoration:'none', color:'white', padding:'10px'}}>
                          <SmsOutlinedIcon style={{marginLeft:'-20px', marginRight:'5px', color:'orange'}}/>
                          VR-chat
                         </a>
                       </Form.Group>

                       <TextArea  chats={chats}/>
                       
                       <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder="text..."
                            onChange={(e)=>setNote(e.target.value)}
                            value={note}
                            />
                       </Form.Group>
                       
                       <Form.Group style={{display:'flex', justifyContent:'center'}}>
                         <a href="#"
                           style={{
                            textDecoration:'none',
                            color:'white',
                            padding:'15px'}} 
                            onClick={(e) => submitsms(e)}
                            >                            
                          Response chat
                         </a>
                       </Form.Group>
                     
                     </Form.Group>
                     {/* DIV CHAT TEST */}



                  </Form.Group>        

                </div>
              
              )}
            </Form.Group>
            {/* PROJECT DETAILS INFORMATION NAME ADDRESS DATE STATUS SERVICES CHAT */}
            {/* PROJECT DETAILS FIRST ZONE */}
            {/*************************** PROJECT DETAILS **************************/}

        
            {/**************************** FILES VIEWER ****************************/}
            {/* FILES VIEWER ZONE */}
            
                {/* FILES VIEWER TITLE */}
                <Form.Group style={{display:'flex'}}>
                  <p
                    style={{
                      color: "gray",
                      fontSize: "30px",
                      fontWeight: "bold",
                      marginTop: "40px",
                      marginBottom: "10px",
                      marginLeft:'50px',
                    }}
                  >
                    Files viewer
                    <InfoIcon
                      style={{
                        padding: "4px",
                        color: "orange",
                        marginLeft: "10px",
                        marginTop: "6px",
                      }}
                    />
                    <small style={{ fontSize: "10px" }}>
                      Click on the badge to get the link
                    </small>
                  </p>
                  {displayiframe === "block"
                  ?
                  <a href="" onClick={(e)=>{
                    e.preventDefault();
                    setDisplayiframe("none");
                  }}>
                  <img src="img/hideeye.png" width='50px' height="60px" 
                    style={{
                      marginTop:'40px', 
                      marginLeft:'30px'
                    }}
                    onClick={(e)=>{
                      e.preventDefault();
                      setDisplayiframe("none");
                    }}
                    />
                  </a>
                  :
                  null}
                </Form.Group>
                {/* FILES VIEWER TITLE */}

                {/* LINKS BADGE MATTERPORT DRONE DEPLOY POLYCAM */}
                <Form.Group>

                  <Accordion style={{display:'flex', marginLeft:'50px'}} flush>
                      
                      <Accordion.Item eventKey="0" onClick={()=>iframeMatterport("matterport")} style={{width:'262px'}}>                    
                          
                          <Accordion.Header>
                                  <img
                                    src="img/matteportlogo.png"
                                    style={{ marginRight: "5px" }}
                                    id="matterport"
                                    onClick={(e) => iframeContainer(e)}
                                  />
                                  <small
                                    style={{ fontSize: "18px" }}
                                    id="matterport"
                                    onClick={(e) => {
                                      iframeContainer(e);
                                    }}
                                  >
                                    Matterport
                                  </small>
                                  <Badge
                                    pill
                                    bg="danger"
                                    style={{
                                      marginBottom: "4px",
                                      marginLeft: "5px",
                                      marginRight: "5px",
                                      fontSize: "15px",
                                    }}
                                    id="matterport"
                                    onClick={(e) => {
                                      iframeContainer(e);
                                    }}
                                  >
                                    {callbacklenght("matterport")}
                                  </Badge>
                          </Accordion.Header>

                          <Accordion.Body 
                            style={{
                              backgroundColor:'#6c6b60', 
                              height:'auto', 
                              display:'flex',
                              flexDirection:'column',
                              alignItems:'center',
                              justifyContent:'center',
                              alignContent:'center',
                              justifyItems:'center',
                              }}> 
                              <ul
                                  style={{
                                    listStyle: "roman inside",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    flexDirection: "column",                                                                        
                                  }}
                                >
                                  {iframecontainer.map((ele) => (
                                    <li style={{ 
                                      fontSize: "12px", 
                                      width: "auto",
                                      marginLeft:'-40px', 
                                      marginTop:'5px',                              
                                      color:'orange',
                                      fontWeight:'bold',
                                      }}>
                                      <a
                                        style={{ textDecoration: "none", color:'white' }}
                                        href=""
                                        key={ele.projfile_id}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setIframelink(ele.projfile_link);
                                          setDisplayiframe("block");
                                        }}
                                      >
                                        {ele.projfile_link.slice(0,20)} 
                                        
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                          </Accordion.Body>
                      </Accordion.Item>



                      <Accordion.Item eventKey="1" onClick={()=>iframeDroneDeploy("dronedeploy")} style={{width:'262px'}}>
                          <Accordion.Header>
                                  <img
                                    src="img/dronedeploylogo.png"
                                    style={{ marginRight: "5px" }}
                                    id="dronedeploy"
                                    onClick={(e) => iframeContainer(e)}
                                  />
                                  <small
                                    style={{ fontSize: "18px" }}
                                    id="dronedeploy"
                                    onClick={(e) => {
                                      iframeContainer(e);
                                    }}
                                  >
                                    Drone Deploy
                                  </small>
                                  <Badge
                                    pill
                                    bg="primary"
                                    style={{
                                      marginBottom: "4px",
                                      marginLeft: "5px",
                                      marginRight: "5px",
                                      fontSize: "15px",
                                    }}
                                    id="dronedeploy"
                                    onClick={(e) => iframeContainer(e)}
                                  >
                                    {callbacklenght("dronedeploy")}
                                  </Badge>
                          </Accordion.Header>

                          <Accordion.Body 
                            style={{
                              backgroundColor:'#6c6b60', 
                              height:'auto', 
                              display:'flex',
                              flexDirection:'column',
                              alignItems:'center',
                              justifyContent:'center',
                              alignContent:'center',
                              justifyItems:'center',
                              }}> 
                              <ul
                                  style={{
                                    listStyle: "roman inside",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    flexDirection: "column",                                                                        
                                  }}
                                >
                                  {iframecontainer.map((ele) => (
                                    <li style={{ 
                                      fontSize: "12px", 
                                      width: "auto",
                                      marginLeft:'-40px', 
                                      marginTop:'5px',                              
                                      color:'orange',
                                      fontWeight:'bold',
                                      }}>
                                      <a
                                        style={{ textDecoration: "none", color:'white' }}
                                        href=""
                                        key={ele.projfile_id}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setIframelink(ele.projfile_link);
                                          setDisplayiframe("block");
                                        }}
                                      >
                                        {ele.projfile_link.slice(0,20)} 
                                        
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                          </Accordion.Body>
                      </Accordion.Item>


                      <Accordion.Item eventKey="2" onClick={()=>iframePolyCam("polycam")} style={{width:'262px'}}>
                          <Accordion.Header>
                              <img
                                src="img/polycamlogo.png"
                                style={{ marginRight: "5px" }}
                                id="polycam"
                                onClick={(e) => iframeContainer(e)}
                              />
                              <small
                                style={{ fontSize: "18px" }}
                                id="polycam"
                                onClick={(e) => {
                                  iframeContainer(e);
                                }}
                              >
                                Poly Cam
                              </small>

                              <Badge
                                pill
                                bg="dark"
                                style={{
                                  marginBottom: "4px",
                                  marginLeft: "5px",
                                  marginRight: "5px",
                                  fontSize: "15px",
                                }}
                                id="polycam"
                                onClick={(e) => iframeContainer(e)}
                              >
                                {callbacklenght("polycam")}
                              </Badge>
                          </Accordion.Header>

                          <Accordion.Body
                          style={{
                            backgroundColor:'#6c6b60', 
                            height:'auto', 
                            display:'flex',
                            flexDirection:'column',
                            alignItems:'center',
                            justifyContent:'center',
                            alignContent:'center',
                            justifyItems:'center',
                            }}> 
                            <ul
                                style={{
                                  listStyle: "roman inside",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  flexDirection: "column",                                                                        
                                }}
                              >
                                {iframecontainer.map((ele) => (
                                  <li style={{ 
                                    fontSize: "12px", 
                                    width: "auto",
                                    marginLeft:'-30px', 
                                    marginTop:'5px',                              
                                    color:'orange',
                                    fontWeight:'bold',
                                    }}>
                                    <a
                                      style={{ textDecoration: "none", color:'white' }}
                                      href=""
                                      key={ele.projfile_id}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setIframelink(ele.projfile_link);
                                        setDisplayiframe("block");
                                      }}
                                    >
                                      {ele.projfile_link.slice(0,20)} 
                                      
                                    </a>
                                  </li>
                                ))}
                              </ul>
                          </Accordion.Body>
                      </Accordion.Item>

                  </Accordion>

                </Form.Group>
                {/* LINKS BADGE MATTERPORT DRONE DEPLOY POLYCAM */}
                
            {/* FILES VIEWER ZONE */}

            {/*  GENERATE LINKS ZONE AND IFRAME 1 */}
                <Form.Group
                  style={{
                    /* border:'1px red dotted', */
                    display: "flex",
                    flexDirection: "column",
                    width: "auto",
                    height: "auto",
                  }}
                >
                  <iframe
                    src={iframelink}
                    style={{
                      marginTop: "5px",
                      width: "auto",
                      height: "600px",
                      display: `${displayiframe}`,
                    }}
                  />
                </Form.Group>
            {/* GENERATE LINKS ZONE AND IFRAME 1 */}
            {/**************************** FILES VIEWER ****************************/}





            {/**************************** PDF VIEWER ******************************/}
            {/* PDF VIEWER ZONE */}
            {/* PDF VIEWER TITLE */}
            <Form.Group style={{display:'flex', marginTop:'30px'}}>
              <p
                style={{
                  color: "gray",
                  fontSize: "30px",
                  fontWeight: "bold",
                  marginTop: "60px",
                  marginBottom: "10px",
                  marginLeft:'50px',
                }}
              >
                Pdf viewer
                <InfoIcon
                  style={{
                    padding: "4px",
                    color: "orange",
                    marginLeft: "10px",
                    marginTop: "6px",
                  }}
                />
                <small style={{ fontSize: "10px" }}>
                  Click on the badge to get the link
                </small>
              </p>
              {displayiframetwo === "block"
                  ?
                  <a href="" onClick={(e)=>{
                    e.preventDefault();
                    setDisplayiframetwo("none");
                  }}>
                  <img src="img/hideeye.png" width='50px' height="60px" 
                    style={{
                      marginTop:'60px', 
                      marginLeft:'30px'
                    }}
                    onClick={(e)=>{
                      e.preventDefault();
                      setDisplayiframetwo("none");
                    }}
                    />
                  </a>
                  :
                null}        
            </Form.Group>
            {/* PDF VIEWER TITLE */}
            {/* LINKS BADGE ELEVATIONS FLOORPLAN AR SITEPLAN ROOFPLAN */}
            <Form.Group>

              <Accordion style={{display:'flex',marginLeft:'50px'}} flush>          

                  <Accordion.Item eventKey="0" onClick={()=>iframeElevation("elevation")}>
                      <Accordion.Header>
                            <small
                              style={{ fontSize: "18px" }}
                              id="elevation"
                              onClick={(e) => iframeContainertwo(e)}
                            >
                              Elevations
                            </small>
                            <Badge
                              pill
                              bg="danger"
                              style={{
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                              id="elevation"
                              onClick={(e) => iframeContainertwo(e)}
                            >
                              {callbacklenght("elevation")}
                            </Badge>
                      </Accordion.Header>

                      <Accordion.Body 
                            style={{
                              backgroundColor:'#6c6b60', 
                              height:'auto', 
                              display:'flex',
                              flexDirection:'column',
                              alignItems:'center',
                              justifyContent:'center',
                              alignContent:'center',
                              justifyItems:'center',
                              }}> 
                              <ul
                                  style={{
                                    listStyle: "roman inside",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    flexDirection: "column",                                                                        
                                  }}
                                >
                                  {iframecontainertwo.map((ele) => (
                                    <li style={{ 
                                      fontSize: "10px", 
                                      width: "auto",
                                      marginLeft:'-40px', 
                                      marginTop:'5px',                              
                                      color:'orange',
                                      fontWeight:'bold',
                                      }}>
                                      <a
                                        style={{ textDecoration: "none", color:'white' }}
                                        href=""
                                        key={ele.projfile_id}
                                      /*  onClick={(e) => {
                                          handleClientLoad(e, ele.projfile_link);
                                        }} */

                                        onClick={(e) => {
                                          handleBlankPage(e, ele.projfile_link);
                                        }}
                                      >
                                        {ele.projfile_link.slice(0,40)} 
                                        
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                      </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1" onClick={()=>iframeFloorPlan("floorplan")}>
                      <Accordion.Header>
                        <small
                          style={{ fontSize: "18px" }}
                          id="floorplan"
                          onClick={(e) => iframeContainertwo(e)}
                        >
                          Floor Plan
                        </small>
                        <Badge
                          pill
                          bg="primary"
                          style={{
                            marginLeft: "5px",
                            marginRight: "5px",
                          }}
                          id="floorplan"
                          onClick={(e) => iframeContainertwo(e)}
                        >
                          {callbacklenght("floorplan")}
                        </Badge>
                      </Accordion.Header>

                      <Accordion.Body 
                              style={{
                                backgroundColor:'#6c6b60', 
                                height:'auto', 
                                display:'flex',
                                flexDirection:'column',
                                alignItems:'center',
                                justifyContent:'center',
                                alignContent:'center',
                                justifyItems:'center',
                                }}> 
                                <ul
                                    style={{
                                      listStyle: "roman inside",
                                      height: "auto",
                                      width: "auto",
                                      display: "flex",
                                      flexDirection: "column",                                                                        
                                    }}
                                  >
                                    {iframecontainertwo.map((ele) => (
                                      <li style={{ 
                                        fontSize: "10px", 
                                        width: "auto",
                                        marginLeft:'-40px', 
                                        marginTop:'5px',                              
                                        color:'orange',
                                        fontWeight:'bold',
                                        }}>
                                        <a
                                          style={{ textDecoration: "none", color:'white' }}
                                          href=""
                                          key={ele.projfile_id}
                                          onClick={(e) => {
                                            handleBlankPage(e, ele.projfile_link);
                                          }}


                                        >
                                          {ele.projfile_link.slice(0,40)} 
                                          
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                      </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="2" onClick={()=>iframeAr("ar")}>
                      <Accordion.Header>
                        <small
                          style={{ fontSize: "18px" }}
                          id="ar"
                          onClick={(e) => iframeContainertwo(e)}
                        >
                          AR
                        </small>
                        <Badge
                          pill
                          bg="black"
                          style={{
                            marginLeft: "5px",
                            marginRight: "5px",
                          }}
                          id="ar"
                          onClick={(e) => iframeContainertwo(e)}
                        >
                          {callbacklenght("ar")}
                        </Badge>
                      </Accordion.Header>
                      
                      <Accordion.Body 
                              style={{
                                backgroundColor:'#6c6b60', 
                                height:'auto', 
                                display:'flex',
                                flexDirection:'column',
                                alignItems:'center',
                                justifyContent:'center',
                                alignContent:'center',
                                justifyItems:'center',
                                }}> 
                                <ul
                                    style={{
                                      listStyle: "roman inside",
                                      height: "auto",
                                      width: "auto",
                                      display: "flex",
                                      flexDirection: "column",                                                                        
                                    }}
                                  >
                                    {iframecontainertwo.map((ele) => (
                                      <li style={{ 
                                        fontSize: "10px", 
                                        width: "auto",
                                        marginLeft:'-40px', 
                                        marginTop:'5px',                              
                                        color:'orange',
                                        fontWeight:'bold',
                                        }}>
                                        <a
                                          style={{ textDecoration: "none", color:'white' }}
                                          href=""
                                          key={ele.projfile_id}
                                          onClick={(e) => {
                                            handleBlankPage(e, ele.projfile_link);
                                          }}
                                        >
                                          {ele.projfile_link.slice(0,40)} 
                                          
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                      </Accordion.Body>        
                  </Accordion.Item>



                  <Accordion.Item eventKey="3" onClick={()=>iframeSitePlan("siteplan")}>
                      <Accordion.Header>
                        <small
                          style={{ fontSize: "18px" }}
                          id="siteplan"
                          onClick={(e) => iframeContainertwo(e)}
                        >
                          Site Plan
                        </small>
                        <Badge
                          pill
                          bg="success"
                          style={{
                            marginLeft: "5px",
                            marginRight: "5px",
                          }}
                          id="siteplan"
                          onClick={(e) => iframeContainertwo(e)}
                        >
                          {callbacklenght("siteplan")}
                        </Badge>
                      </Accordion.Header>

                      <Accordion.Body 
                              style={{
                                backgroundColor:'#6c6b60',  
                                height:'auto', 
                                display:'flex',
                                flexDirection:'column',
                                alignItems:'center',
                                justifyContent:'center',
                                alignContent:'center',
                                justifyItems:'center',
                                }}> 
                                <ul
                                    style={{
                                      listStyle: "roman inside",
                                      height: "auto",
                                      width: "auto",
                                      display: "flex",
                                      flexDirection: "column",                                                                        
                                    }}
                                  >
                                    {iframecontainertwo.map((ele) => (
                                      <li style={{ 
                                        fontSize: "10px", 
                                        width: "auto",
                                        marginLeft:'-40px', 
                                        marginTop:'5px',                              
                                        color:'orange',
                                        fontWeight:'bold',
                                        }}>
                                        <a
                                          style={{ textDecoration: "none", color:'white' }}
                                          href=""
                                          key={ele.projfile_id}
                                          onClick={(e) => {
                                            handleBlankPage(e, ele.projfile_link);
                                          }}
                                        >
                                        {ele.projfile_link.slice(0,40)} 
                                          
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                      </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="4" onClick={()=>iframeRoofPlan("roofplan")}>
                        <Accordion.Header>
                          <small
                            style={{ fontSize: "18px" }}
                            id="roofplan"
                            onClick={(e) => iframeContainertwo(e)}
                          >
                            Roof Plan
                          </small>
                          <Badge
                            pill
                            bg="warning"
                            style={{
                              marginLeft: "5px",
                              marginRight: "5px",
                            }}
                            id="roofplan"
                            onClick={(e) => iframeContainertwo(e)}
                          >
                            {callbacklenght("roofplan")}
                          </Badge>
                        </Accordion.Header>

                        <Accordion.Body 
                              style={{
                                backgroundColor:'#6c6b60', 
                                height:'auto', 
                                display:'flex',
                                flexDirection:'column',
                                alignItems:'center',
                                justifyContent:'center',
                                alignContent:'center',
                                justifyItems:'center',
                                }}> 
                                <ul
                                    style={{
                                      listStyle: "roman inside",
                                      height: "auto",
                                      width: "auto",
                                      display: "flex",
                                      flexDirection: "column",                                                                        
                                    }}
                                  >
                                    {iframecontainertwo.map((ele) => (
                                      <li style={{ 
                                        fontSize: "10px", 
                                        width: "auto",
                                        marginLeft:'-40px', 
                                        marginTop:'5px',                              
                                        color:'orange',
                                        fontWeight:'bold',
                                        }}>
                                        <a
                                          style={{ textDecoration: "none", color:'white' }}
                                          href=""
                                          key={ele.projfile_id}
                                          onClick={(e) => {
                                            handleBlankPage(e, ele.projfile_link);
                                          }}
                                        >
                                        {ele.projfile_link.slice(0,40)}  
                                          
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                        </Accordion.Body>
                  </Accordion.Item>        
              
              
              </Accordion>

            </Form.Group>
            {/* LINKS BADGE ELEVATIONS FLOORPLAN AR SITEPLAN ROOFPLAN */}
            {/* PDF VIEWER ZONE */}
            {/*  GENERATE LINKS ZONE AND IFRAME 2 */}
            <Form.Group
              style={{
                display: "flex",
                flexDirection: "column",
                width: "auto",
                height: "auto",
                marginTop:'20px'
              }}
            >

               {pdfloading === true
               ?
                <Container
                 style={{
                 width: "auto",
                 height:'auto',
                 display: "flex",
                 justifyContent: "center",
                 alignContent: "center",
                 alignItems: "center",                 
                  }}
                >
                 <Puff color="orange" height={320} width={120} style={{marginTop:'-30px'}} />
                </Container>
               :
               <iframe src={iframelinktwo && iframelinktwo}
                /*  src={iframelinktwo && `${iframelinktwo}#toolbar=0&navpanes=0&scrollbar=0`} */
                /*  src={iframelinktwo && `${iframelinktwo}#navpanes=0&scrollbar=0`} */
                 style={{
                 marginTop: "5px",
                 width: "1100",
                 height: "880px",
                 display: `${displayiframetwo}`,
                 }}
               />               
              } 
            </Form.Group>
            {/*  GENERATE LINKS ZONE AND IFRAME 2 */}
            {/**************************** PDF VIEWER ******************************/} 


            {/*************************** FILES DOWNLOAD ***************************/}
            {/* FILES DOWNLOAD TITLE */}
            <Form.Group style={{display:'flex', marginTop:'30px'}}>
              <p
                style={{
                  color: "gray",
                  fontSize: "30px",
                  fontWeight: "bold",
                  marginTop: "40px",
                  marginBottom: "-10px",
                  marginLeft:'50px',
                }}
              >
                Files downloads
                <InfoIcon
                    style={{
                      padding: "4px",
                      color: "orange",
                      marginLeft: "10px",
                      marginTop: "6px",
                      }}
                    />
                    <small style={{ fontSize: "10px"}}>
                      Click on the image to download file
                    </small>
              </p>
            </Form.Group>
            {/* FILES DOWNLOAD TITLE */}
            {/* FILES DOWNLOAD BADGE PDF DOCUMENTS */}
            <Form.Group
              style={{
                width: "auto",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                marginLeft:'50px',
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CloudDownloadIcon
                  style={{
                    fontSize: "60px",
                  }}
                />
                <Badge
                  pill
                  bg="secondary"
                  style={{
                    fontSize: "15px",
                  }}
                >
                  {callbackdownloadlenght()}
                </Badge>
                <p>
                  Files
                </p>
              </p>
              <Form.Group
                style={{
                  display: "flex",
                  marginLeft: "40px",
                }}
              >
                {downloadcontainer.map((ele) =>
                  ele.projfile_linkType === "cad" ? (
                    <div>
                      <p style={{marginLeft: "42px",fontSize: "12px",marginTop: "30px",fontWeight: "bold"}}>
                        {ele.projfile_linkType}
                      </p>  
                      <a href="#">             
                        <div className="dwgs"
                          onClick={(e)=>{
                          e.preventDefault()
                           GenerateDownloadLink(ele.projfile_link)
                          }}/>        
                      </a>
                      <a href="#" style={{ textDecoration: "none" }} 
                          onClick={(e)=>{
                             e.preventDefault() 
                             GenerateDownloadLink(ele.projfile_link)
                             }}>
                        <p style={{marginLeft:"30px",fontSize:"10px",fontWeight:"bold"}}>
                            Download
                        </p>
                      </a>
                    </div>
                  ) : (
                    <div>
                      <p style={{marginLeft: "30px",fontSize: "12px",marginTop: "30px",fontWeight: "bold"}}>
                        {ele.projfile_linkType}
                      </p>
                      <a href="">             
                        <div className="pedefes"
                          onClick={(e)=>{
                            e.preventDefault();
                            GenerateDownloadLink(ele.projfile_link)
                            }}/>        
                      </a>
                      <a href="" style={{ textDecoration: "none" }} 
                          onClick={(e)=>{
                            e.preventDefault();
                            GenerateDownloadLink(ele.projfile_link)
                            }}>
                        <p style={{marginLeft:"30px",fontSize:"10px",marginTop:"5px",fontWeight:"bold"}}>
                          Download
                        </p>
                      </a>
                    </div>
                  )
                )}
              </Form.Group>
            </Form.Group>
            {/* FILES DOWNLOAD BADGE PDF DOCUMENTS */}
            {/*************************** FILES DOWNLOAD ***************************/}
            {/************************** INVOICE INFORMATION & DOWNLOAD ************/}
            {/* INVOICE INFORMATION TITLE */}
            <Form.Group style={{display:'flex', marginTop:'30px'}}>
              <p
                style={{
                  color: "gray",
                  fontSize: "30px",
                  fontWeight: "bold",
                  marginTop: "20px",
                  marginLeft:'50px',
                }}
              >
                Invoice information
                <InfoIcon
                  style={{
                  padding: "4px",
                  color: "orange",
                  marginLeft: "10px",
                  marginTop: "6px",
                  }}
                />
                  <small style={{ fontSize: "10px"}}>
                    Click on the link to pay
                  </small>
              </p>
            </Form.Group>
            {/* INVOICE INFORMATION TITLE */}
            {/* INVOICE DETAILS AND WAVEAPP LINK */}
            <Form.Group
            style={{
              marginLeft:'50px',
            }}
            >
              <Table
                striped
                bordered
                hover
                responsive
                style={{width:'auto'}}
              >
                <thead>
                  <tr style={{backgroundColor:'rgb(77, 91, 103)', color:'white'}}>
                    <th style={{width:'200px', textAlign:'center'}}>Wave app invoice id</th>
                    <th style={{width:'200px', textAlign:'center'}}>Wave status</th>
                    <th style={{width:'400px', textAlign:'center'}}>Link wave app</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{backgroundColor:'white'}}>
                    <td style={{width:'200px', textAlign:'center'}}> {waveappid}</td>
                    <td style={{width:'200px', textAlign:'center'}}> {waveappstatus}</td>
                    <td style={{width:'400px', textAlign:'center'}}>
                      <a
                        rel={"external"}
                        className="fab fa-instagram"
                        target="_blank"
                        href={linkwaveapp}
                        style={{ color: "blue", textDecoration: "none" }}
                      >
                        {linkwaveapp}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Form.Group>
            {/* INVOICE DETAILS AND WAVEAPP LINK */}
            {/***************************  INVOICE INFORMATION & DOWNLOAD ************/}
            <Form.Group style={{ height: "30px" }}>
            </Form.Group>
        </Container>
    </Container>

  
  );
};

export default Details;
