import { Form } from 'react-bootstrap';
import './crudtableclientrow.css';
import { useEffect } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default function Crudtableclientrow({ el }) {
  const DeleteProject = () => {};
  const handleClick = () => {};

  return (
    <tr>
      <td>
        <Form.Check type="checkbox" value={el.id} />
      </td>
      <td>{el.cli_name}</td>
      <td>{el.cli_email}</td>
      <td>{el.company}</td>
      <td>{el.cli_active}</td>
      <td>{el.role}</td>
      <td>{el.type}</td>
      <td>
        <a href="#" title="Edit project">
          <EditIcon
            role="button"
            style={{ color: 'rgb(64, 192, 243)' }}
            onClick={(e) => handleClick(e)}
          />
        </a>
        <a href="#" title="Delete project">
          <DeleteForeverIcon
            role="button"
            style={{ color: '#eb2b54' }}
            onClick={(e) => DeleteProject(e)}
          />
        </a>
      </td>
    </tr>
  );
}
