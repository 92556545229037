import "./crudtablerow.css";
import { useState, useEffect } from "react";
import useAuth from "../../auth/useAuth";
import { useNavigate, useBlocker } from "react-router-dom";
import { Form } from "react-bootstrap";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Link } from "react-router-dom";
import moment from "moment";




export default function CrudTableRow({ el, setDb, projectid }) {
  
  const navigate = useNavigate();
  const auth = useAuth();
  const [backdate, setBackdate] = useState(el.dataCreated);
  const [idchecked, setIdchecked] = useState(false);
  const [deleteid, setDeleteid] = useState();
  const clientid = parseInt(window.localStorage.getItem('user_client_id'));
  


  const companiestosingleobject = [
    "GMBC",
    "Wise Builders",
    "Diego Valencia",
    "Bay Remodeling",
    "InnovoBuilders",
    "Liane Carter Interiors",
    "Nuvision Construction",
    "Oasis Home Design",
    "Oro Coast Builders",
    "Velvet Home Staging",
    "Tiffany",
    "Sunny",
    "Sueling",
    "Karen",
    "Mr & Mrs. Construction & Remodeling",
    "Deco Builders",
    "Independent",
    "Architec or Home Designer",
    "Vrealism",
    "A.O Positive Solutions",
    "Arcus Architecture",
    "ATC Design Center",
    "Baron Constructions",
    "BROTHER AND BROTHER BUILDERS",
    "CA Bear Constructions",
    "DelSur Construction",
    "Direct Home Remodeling Inc",
    "Edri Construction ",
    "Future Vision Remodeling",
    "Han DesignStudio",
    "Home by Tom",
    "Homeowners",
    "Lecut Construction",
    "Nova Builders",
    "On Design & Remodeling",
    "Prime Renovation Homes",
    "QA Buillders",
    "Re-Model Design & Build Co",
    "Realty Builders",
    "Skytech Builders",
    "The Building Company",
    "Wealthentech Inc",
  ];




  const clientosinglearray =  [
    "admin",
    "Joseph",
    "Vrealism",
    "Diego Valencia",
    "Amit",
    "Karen",
    "Liane",
    "Sunny",
    "Alejandro Vargas",
    "Eli",
    "tester",
    "clientsteven",
    "Miguel",
    "Ruben",
    "Catalina",
    "Laura", 
    "Juan", 
    "Edwin",
    "abs",
    "Alejandro Vargas", 
    "aswdd",
    "eperez", 
    "Sueling",
    "dfre",
    "dferf",
    "Bay Remodeling",
    "InnovoBuilders",
    "Liane Carter Interiors",
    "Tomio",
    "Daniel Fonseca", 
    "Ben Megidesh",
    "Velvet Home Staging", 
    "Tiffany",
    "Sunny",
    "Mr. & Mrs",
    "Tal Buchnick",
    "Architec or Home Designer",
    "Oliver Nery",
    "Rex Mclean",
    "Zamin Zohoor", 
    "Ramin Zohoor",
    "Tiffany Huang",
    "Michael Spehar",
    "Leo Ben Atar",
    "Avi",
    "Sal Taraboulsi",
    "Aviv Shumel",
    "Yehuda",
    "Michael",
    "Alvin G Rivera",
    "Ammar Mukadam",
    "Greg Komar",
    "Manjing Xie",
    "Greg Komar",
    "Manjing Xie",
    "Jania Sawyer",
    "info@homebytom",
    "Mose",
    "Mona Gilardi",
    "Julian Barak",
    "Karen Lok",
    "David Gavy",
    "Oscar",
    "Talor Shlomo",
    "Briand", "Tal",
    "Sunny Gao",
    "Ofir Ayash", 
    ];

  /**/
  const DeleteProject = (e) => {
    const confirms = window.confirm(
      `Are you sure, to delete project id: ${el.id} from ${el.company}.`
    );
    if (confirms) {
      fetch("https://node.vrealism.com:5000/delete/home/project", {
        method: "POST",
        body: JSON.stringify({
          projectid,
          clientid,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((res) => {
          const data = res.map((items) => ({
            id: items.proj_id,
            projectAddress: items.proj_address,
            status: items.proj_status,
            company: companiestosingleobject[items.proj_houseOwnerName - 1],
            dataCreated: items.proj_dateCreated,
            client: clientosinglearray[items.client_cli_id - 1],  
          }));
          setDb(data);
        });
    } else {
      false();
    }
  };

  const handleClick = (e) => {
    e.preventDefault();    
   /*  auth.setProjid(el.id); */
    window.localStorage.setItem('projectid', JSON.stringify(el.id).replace(/["']/g, ""));
    navigate("/edit");
  };
  const handleInfo = (e) => {
    e.preventDefault();
  /*   auth.setProjid(el.id);  */
  window.localStorage.setItem('projectid', JSON.stringify(el.id).replace(/["']/g, ""));
  navigate("/info");
  };
  const handleCheck = (e) => {
    setIdchecked(e.target.checked);
  };


  return (
    <tr className="tablerow" key={el.id}>
      <td style={{ borderRight: "1px solid white" }}>
        <Form.Check
          type="checkbox"      
          checked={auth.idcheck === el.id ? true : false}
          value={el.id}
          onChange={(e) => handleCheck(e)}
        />
      </td>
      <td style={{ borderRight: "1px solid white" }}>
        <a href="#" onClick={(e) => handleInfo(e)}>
          {el.projectAddress}
        </a>
      </td>
      <td
        style={{ borderRight: "1px solid white", color: "rgb(122, 113, 113)" }}
      >
        {moment.utc(backdate).format("MMM Do YYYY")}
      </td>
      <td
        style={{ borderRight: "1px solid white", color: "rgb(122, 113, 113)" }}
      >
        {el.status}
      </td>
      <td
        style={{ borderRight: "1px solid white", color: "rgb(122, 113, 113)" }}
      >
        {el.company}
      </td>
      <td style={{ borderRight: "1px solid white", color: "rgb(122, 113, 113)" }}>
        {el.client}
      </td>
      <td>
        <a href="#" title="Project details"> 
        <InfoIcon
          role="button"
          style={{ color: "orange" }}
          onClick={(e) => handleInfo(e)}
        />
        </a>
        <a href="#" title="Edit project">
        <EditIcon
          role="button"
          style={{ color: "rgb(64, 192, 243)" }}
          onClick={(e) => handleClick(e)}
        />
        </a>
        <a href="#" title="Delete project">
        <DeleteForeverIcon
          role="button"
          style={{ color: "#eb2b54" }}
          onClick={(e) => DeleteProject(e)}
        />
        </a>
      </td>
    </tr>
  );
}
