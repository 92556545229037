import "./modal.css";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import SaveIcon from "@mui/icons-material/Save";
import DatePicker from "react-datepicker";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import StorageIcon from '@mui/icons-material/Storage';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SendIcon from "@mui/icons-material/Send";
import { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import useAuth from "../../auth/useAuth";
import {
  Audio,
  BallTriangle,
  ThreeDots,
  TailSpin,
  Rings,
  Puff,
  Oval,
  Hearts,
  Grid,
  Circles,
  Bars,
} from "react-loader-spinner";
import moment from "moment";
import {
  Form,
  Button,
  FloatingLabel,
  FormGroup,
  Label,
  Nav,
  Navbar,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { width } from "@mui/system";
import Geocode from "react-geocode";
import TextAdminArea from "./TextAdminArea";

export default function Modal() {
  //*********************************************************** //
  /////////////////CONSTANTS//////////////////////////////////////
  //*********************************************************** //
  const auth = useAuth();
  auth.setClient(window.localStorage.getItem('cli_name'));
  /*  auth.setClient(backjson[0].cli_name); */
  auth.setRole(window.localStorage.getItem('role'));
   /* auth.setRole(backjson[0].role); */
  auth.setType(window.localStorage.getItem('type'));
  /*  auth.setType(backjson[0].type); */
  const inputRef = useRef();
  const inputRefSqft = useRef();
  const inputRefHomeOwner = useRef();
  const inputRefPhoneOwner = useRef();
  const inputRefMailOwner = useRef();
  const inputRefStorage = useRef();
  const inputRefStorageGreen = useRef();
  const sendingInvoice = useRef();
  const navigate = useNavigate();
  const [idproj, setIdproj] = useState(parseInt(window.localStorage.getItem('projectid')));
  const [form, setForm] = useState([]);
  const [services, setServices] = useState([]);
  const [servicestable, setServicestable] = useState([]);
  const [homeaddress, setHomeaddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [resultsto, setResultsto] = useState([]);
  const [sqft, setSqft] = useState("");
  const [homeownername, setHomeownername] = useState("");
  const [homeownerphone, setHomeownerphone] = useState("");
  const [homeowneremail, setHomeowneremail] = useState("");
  const [projecthouseownername, setProjecthouseownername] = useState("");
  const [jobday, setJobday] = useState("");
  const [formatjobday, setFormatjobday] = useState();
  const [time, setTime] = useState("");
  const [contractor, setContractor] = useState("");
  const [clientcliid, setClientcliid] = useState("");
  const [details, setDetails] = useState("");
  const [status, setStatus] = useState("");
  const [surveyor, setSurveyor] = useState("");
  const [architec, setArchitec] = useState("");
  const [surveyors, setSurveyors] = useState([]);
  const [architecs, setArchitecs] = useState([]);
  const [currentclient, setCurrentclient] = useState(window.localStorage.getItem('user_client_id'));
  const [currentservices, setCurrentservices] = useState([]);
  const [vrnotes, setVrnotes] = useState("");
  const [projectfolders, setProjectfolders] = useState([]);
  const [linkmatterport, setLinkmatterport] = useState("");
  const [linkpolycam, setLinkpolycam] = useState("");
  const [linkdronedeploy, setLinkdronedeploy] = useState("");
  const [linkgoogledrive, setLinkgoogledrive] = useState("");
  const [finalplantype, setFinalplantype] = useState(null);
  const [loading, setLoading] = useState(false);
  const [writedb, setWritedb] = useState(false);
  const [finalplanlink, setFinalplanlink] = useState(null);
  const [invoiceid, setInvoiceid] = useState("");
  const [invoicelink, setInvoicelink] = useState("");
  const [invoicestatus, setInvoicestatus] = useState("");
  const [waveappid, setWaveappid] = useState("");
  const [waveapplink, setWaveapplink] = useState("");
  const [waveappstatus, setWaveappstatus] = useState("");
  const [clientsbycompany, setClientsbycompany] = useState([]);
  const [filtercontractor, setFiltercontractor] = useState([]);
  const [filtersurveyor, setFiltersurveyor] = useState([]);
  const [filterarchitec, setFilterarchitec] = useState([]);

  const initialinvoicestatus = [
    { id: 1, name: "Paid" },
    { id: 2, name: "Pending" },
    { id: 3, name: "Generated" },
    { id: 4, name: "Returned" },
    { id: 5, name: "Posted" },
  ];
  const [invoicelist, setInvoicelist] = useState(initialinvoicestatus);
  const initialstatus = [
    { id: 1, current: "Assigned", checked: false },
    { id: 2, current: "Working", checked: false },
    { id: 3, current: "Renew", checked: false },
    { id: 4, current: "Delivered", checked: false },
    { id: 5, current: "Adjustment", checked: false },
    { id: 6, current: "Cancel", checked: false },
    { id: 7, current: "Created", checked: false },
  ];
  const [statuslist, setStatuslist] = useState(initialstatus);
  const initialChat = [
    {
      id: null,
      client: "",
      sms: "",
    },
  ];

  const [chats, setChats] = useState(initialChat);
  const clients = [
    {
      id: 1,
      name: "admin",
    },
    {
      id: 2,
      name: "wisebuilders",
    },
    {
      id: 3,
      name: "vrealism",
    },
    {
      id: 4,
      name: "diego",
    },
    {
      id: 5,
      name: "amit",
    },
    {
      id: 6,
      name: "karen",
    },
    {
      id: 7,
      name: "liane",
    },
    {
      id: 8,
      name: "sunny",
    },
    {
      id: 9,
      name: "tester",
    },
    {
      id: 10,
      name: "gmbc",
    },
    {
      id: 19,
      name: "Developer",
    },
    {
      id: 20,
      name: "Alejandro Vargas",
    },
    {
      id: 21,
      name: "Alejandro Carvajal",
    },
  ];
  const initialboxes = [
    {
      id: 1,
      checked: false,
      item: "Floor Plan",
    },
    {
      id: 2,
      checked: false,
      item: "Site Plan",
    },
    {
      id: 3,
      checked: false,
      item: "Elevation",
    },
    {
      id: 4,
      checked: false,
      item: "Roof Plan(Drone)",
    },
    {
      id: 5,
      checked: false,
      item: "Drone Video",
    },
    {
      id: 6,
      checked: false,
      item: "Drone Photography",
    },
  ];
  const [checkboxes, setCheckboxes] = useState(initialboxes);
  const [chekes, setChekes] = useState([]);
  const [issending, setIssending] = useState(false);


  //*********************************************************** //
  /////////////////CONSTANTS//////////////////////////////////////
  //*********************************************************** //


  const utc = new Intl.DateTimeFormat("en-US", {
    timeZone: "UTC",
    dateStyle: "full",
  })

  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //


  ///////////////FORMAT JOBDAY////////////////


  
  const eraseAddress = ()=>
  {   
    setHomeaddress("");
    setState("");
    setCity("");
    setZipcode("");
    inputRef.current.focus();          
  }
  const eraseSqft = ()=>
  {
    setSqft("");
    inputRefSqft.current.focus();
  }
  const eraseHomeName = ()=>
  {
    setHomeownername("");
    inputRefHomeOwner.current.focus();
  }
  const eraseHomePhone = ()=>
  {
    setHomeownerphone("");
    inputRefPhoneOwner.current.focus();
  }
  const eraseHomeMail = ()=>
  {
    setHomeowneremail("");
    inputRefMailOwner.current.focus();
  }
 

  ///////////////FORMAT JOBDAY////////////////



  //Bring all  proj´s table by projid//
  useEffect(() => {
    setLoading(true);
    fetch("https://node.vrealism.com:5000/api/getprojbyid", {
      method: "POST",
      body: JSON.stringify({
        idproj,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        setForm(res);
        setClientcliid(res[0].client_cli_id);
        const dateback = res[0].proj_dateAssigned;
        if(dateback === "0000-00-00 00:00:00")
        {
         setJobday("")
        }else{
        const dateformat = new Date(dateback);     
        const utc = new Intl.DateTimeFormat("fr-CA",{
          timeZone:"UTC",
          day:'2-digit',
          month:'2-digit',
          year:'numeric',  
          }).format(dateformat)
        setJobday(utc);
        }
        setTime(res[0].sethour);
        setHomeaddress(res[0].proj_address);
        setSqft(res[0].squarefeet);
        setHomeownername(res[0].home_owner_name);
        setHomeownerphone(res[0].home_owner_phone);
        setHomeowneremail(res[0].home_owner_email);
        setProjecthouseownername(res[0].proj_houseOwnerName);
        setSurveyor(res[0].surveyor);
        setArchitec(res[0].architec);
        setDetails(res[0].proj_description);
        setStatus(res[0].proj_status);
        const clientid = res[0].client_cli_id;
        fetch("https://node.vrealism.com:5000/api/clientnamebyid", {
          method: "POST",
          body: JSON.stringify({
            clientid,
          }),
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
          .then((json) => {
            setContractor(json[0].cli_name);
            setLoading(false);
          });
      });
  }, [idproj]);
  //Bring all  proj´s table by projid//

  //*********************************************************** //

  //Use Geolocalitation//
  useEffect(() => {
    Geocode.setApiKey("AIzaSyBNPVvrMUVEGNXollqj4pQO0czA8RaRGRk");
    Geocode.setLanguage("en");
    Geocode.setRegion("us");
    Geocode.setLocationType("ROOFTOP");
    Geocode.enableDebug();
    Geocode.fromAddress(homeaddress).then(
      (response) => {
        setZipcode(response.results[0].address_components[8].short_name);
        setState(response.results[0].address_components[5].short_name);
        setCity(response.results[0].address_components[3].short_name);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [homeaddress]);
  //Use Geolocalitation//


  /////////////////FUNCTIONS//////////////////////////////////////
  //*********************************************************** //
  //*********************************************************** //

  //Send Invoce//
  const Sendinvoice = (e) => {
    e.preventDefault();
    if (waveappid === "" || waveapplink === "" || waveappstatus === "") {
      alert("Complete the invoice data");
      setWaveappid("");
      setWaveapplink("");
      setWaveappstatus("");
      return false;
    }
    setWritedb(true)
    sendingInvoice.current.style.color="red";
    sendingInvoice.current.innerHTML="Sending invoice ..."
    fetch("https://node.vrealism.com:5000/invoice", {
      method: "POST",
      body: JSON.stringify({
        idproj,
        clientcliid,
        waveappid,
        waveapplink,
        waveappstatus,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) => {      
        setWaveappid(json[0].num_invoice);
        setWaveappstatus(json[0].inv_status);
        setWaveapplink(json[0].linkapp);
        sendingInvoice.current.style.color="aqua";
        sendingInvoice.current.innerHTML="Invoice stored.";
        setTimeout(()=>{
          setWritedb(false);
        },1700)       
      });
  };
  //Send Invoice//

  //Render Address & state & city & zipconde //
  const RenderAddress = (e) => {

   /*  inputRefStorage.current.style.color = "#46f509";  */     
    if (e.target.value === null || undefined) {
      setHomeaddress(null);
      setState(null);
      setCity(null);
      setZipcode(null);
    } else {     
     
      setHomeaddress(e.target.value);  
      setWritedb(true);     
      Geocode.setApiKey("AIzaSyBNPVvrMUVEGNXollqj4pQO0czA8RaRGRk");
      Geocode.setLanguage("en");
      Geocode.setRegion("us");
      Geocode.setLocationType("ROOFTOP");
      Geocode.enableDebug();
      Geocode.fromAddress(e.target.value).then(
        (response) => {          
          setResultsto(response);
          setZipcode(response.results[0].address_components[8].short_name);
          setState(response.results[0].address_components[5].short_name);
          setCity(response.results[0].address_components[3].short_name);
        },
        (error) => {
          console.error(error);
        }       
      ).then((li)=>{
         
        setTimeout(()=>{
          setWritedb(false)
         },2000)

      })           
    }
  };
  //Render Address & state & city & zipconde //

  //*********************************************************** //

  //Toggle the switchbutton //
  const handleSwitch = (e) => {
    /* const listItems = statuslist.map((el) =>
      el.id === e ? { ...el, checked: !el.checked } : el 
    );*/
    setWritedb(true);
    setStatus(e.current);
    //setStatuslist(listItems);
    const currentstatus = e.current;
    fetch("https://node.vrealism.com:5000/update/status", {
      method: "POST",
      body: JSON.stringify({
        idproj,
        currentstatus,
      }),
      headers: { "Content-Type": "application/json" },
    })
    .then((res)=>res.json())
    .then((json)=>{
      
      setTimeout(()=>{
        setWritedb(false);
      },1700)
  
    })
  };
  //Toggle the switchbutton //

  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //
  //Toogle the invoice status///
  const handleInvoiceSwitch = (e) => {
    setInvoicestatus(e.name);
    setWaveappstatus(e.name);
  };
  //Toogle the invoice status///
  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

  //Toggle the checkboxes//
  //flashhang//
  const flashhang = (el) => {
    setWritedb(true)
    const listitems = chekes.map((item) =>
      item.id === el.id ? { ...item, checked: !item.checked } : item
    );
    setChekes(listitems);
    const tag = el.label;
    const servid = el.id;
    const checked = el.checked;
    const cliente = clientcliid;
    fetch("https://node.vrealism.com:5000/reinsertservices", {
      method: "POST",
      body: JSON.stringify({
        idproj,
        tag,
        servid,
        checked,
        cliente,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) =>{

        setTimeout(()=>{
          setWritedb(false)
        },1700)   
              
      } );
  };
  //flashhang//
  //Toggle the checkboxes//

  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

  //*********************************************************** //
  /////////////////FUNCTIONS//////////////////////////////////////
  //*********************************************************** //

  //Update online surveyor//
  const updateSurveyor = (e) => {
    setWritedb(true);  
    const updatesurveyor = e.target.value;
    fetch("https://node.vrealism.com:5000/updatesurveyor", {
      method: "POST",
      body: JSON.stringify({
        idproj,
        updatesurveyor,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) =>{
        setTimeout(()=>{
          setWritedb(false);
        },1700)       
      });
  };
  //Update online surveyor//

  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

  //Update online surveyor//
  const updateArchitec = (e) => {
    setWritedb(true)
    const updatearchitec = e.target.value;
    fetch("https://node.vrealism.com:5000/updatearchitec", {
      method: "POST",
      body: JSON.stringify({
        idproj,
        updatearchitec,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) =>{
        setTimeout(()=>{
          setWritedb(false);
        },1700)
          
      } );
  };
  //Update online surveyor//
  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

  // Matterport send link data   //
  const linkmatterContainer = (e) => {    
    e.preventDefault();   
    if (linkmatterport === "") {
      alert("Paste a link");
      return false;
    }
    setWritedb(true);
    const linktype = e.target.viewportElement.id;
    const description = "tour";
    const link = linkmatterport;
    fetch("https://node.vrealism.com:5000/projectfiles", {
      method: "POST",
      body: JSON.stringify({
        idproj,
        link,
        linktype,
        clientcliid,
        description,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) =>
      {
        setProjectfolders(json);
        setLinkmatterport("");
        setTimeout(()=>{
          setWritedb(false);
        },1700)
      });   
  };
  // Matterport send link data   //
  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //
  //Polycam send link data //
  const linkpolyContainer = (e) => {
    e.preventDefault();
    if (linkpolycam === "") {
      alert("Paste a link");
      return false;
    }
    setWritedb(true);
    const linktype = e.target.viewportElement.id;
    const description = "tour";
    const link = linkpolycam;
    fetch("https://node.vrealism.com:5000/projectfiles", {
      method: "POST",
      body: JSON.stringify({
        idproj,
        link,
        linktype,
        clientcliid,
        description,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) =>{
        setProjectfolders(json)
        setLinkpolycam("");
        setTimeout(()=>{
          setWritedb(false);
        },1700)
      });
  };
  //Polycam send link data //
  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //
  //Droneploy send link data //
  const linkdroneContainer = (e) => {
    e.preventDefault();
    if (linkdronedeploy === "") {
      alert("Paste a link");
      return false;
    }
    setWritedb(true);
    const linktype = e.target.viewportElement.id;
    const description = "tour";
    const link = linkdronedeploy;
    fetch("https://node.vrealism.com:5000/projectfiles", {
      method: "POST",
      body: JSON.stringify({
        idproj,
        link,
        linktype,
        clientcliid,
        description,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) => 
      {
        setProjectfolders(json);
        setLinkdronedeploy("");
        setTimeout(()=>{
          setWritedb(false);
        },1700)
      }     
      );
  };
  //Droneploy send link data //
  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //
  // Googledrive send link data//
  const linkgoogledriveContainer = (e) => {
    e.preventDefault();
    if (linkgoogledrive === "") {
      alert("Paste a link");
      return false;
    }
    setWritedb(true);
    const linktype = e.target.viewportElement.id;
    const description = "tour";
    const link = linkgoogledrive;
    fetch("https://node.vrealism.com:5000/projectfiles", {
      method: "POST",
      body: JSON.stringify({
        idproj,
        link,
        linktype,
        clientcliid,
        description,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) =>{
        setProjectfolders(json);
        setLinkgoogledrive("");
        setTimeout(()=>{
          setWritedb(false);
        },1700)
      }) 
  };
  // Googledrive send link data//

  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //
  // Final plans send link data//
  const linkfinalplanContainer = (e) => {
    e.preventDefault();
    if (!finalplanlink || !finalplantype) 
    {
      alert("Paste a link");
      return false;
    }
    setWritedb(true);
    const description = "tour";
    const linktype = finalplantype;
    const link = finalplanlink;
    fetch("https://node.vrealism.com:5000/projectfiles", {
      method: "POST",
      body: JSON.stringify({
        idproj,
        link,
        linktype,
        clientcliid,
        description,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) => {

        setProjectfolders(json)
        setFinalplanlink("");
        setTimeout(()=>{
          setWritedb(false);
        },1700)
        
      });
  };
  // Final plans send link data//

  //*********************************************************** //
  /////////////////FUNCTIONS//////////////////////////////////////
  //*********************************************************** //

  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

  // clearSpace of matterport link //
  const clearmatterlinkSpace = (e) => {
    e.preventDefault();
    setLinkmatterport("");
  };
  // clearSpace of matterport link //
  //****************************** //
  //****************************** //
  // clearSpace of polycam link //
  const clearpolylinkSpace = (e) => {
    e.preventDefault();
    setLinkpolycam("");
  };
  // clearSpace of polycam link //
  //**************************** **//
  //****************************** //
  // clearSpace of dronedeploy link //
  const cleardronelinkSpace = (e) => {
    e.preventDefault();
    setLinkdronedeploy("");
  };
  // clearSpace of dronedeploy link //
  //**************************** **//
  //****************************** //
  //clearSpace of googledrive link//
  const cleargoogledrivelinkSpace = (e) => {
    e.preventDefault();
    setLinkgoogledrive("");
  };
  //clearSpace of googledrive link//
  //**************************** **//
  //****************************** //
  //clearSpace of finalplan link//
  const clearfinalplanlinkSpace = (e) => {
    e.preventDefault();
    setFinalplanlink("");
  };
  //clearSpace of finalplan link//

  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

  // delete Links Final  //
  const deletefinalLink = (e) => {
    e.preventDefault();
    setWritedb(true);
    const linkid = e.target.viewportElement.id;
    fetch("https://node.vrealism.com:5000/delete/links", {
      method: "POST",
      body: JSON.stringify({
        linkid,
        idproj,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) =>{
        setProjectfolders(json)
        setTimeout(()=>{
          setWritedb(false);
        },1700)
      } );
  };
  // delete Links Final //

  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

  //////////////UPDATE SECTION ROOT FORM//////////////////
  //Update sqft//
  const updateFormSqft = (e) => {
    setWritedb(true)
    const fieldvalue = e.target.value;
    fetch("https://node.vrealism.com:5000/update/project/sqft", {
      method: "POST",
      body: JSON.stringify({
        fieldvalue,
        idproj,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) =>
      { 
        setTimeout(()=>{
          setWritedb(false);
        },1700)
       
       /*  inputRefStorage.current.style.color = "white"; */
      } );
  };
  //Update sqft//
  ////////////////////////////////////////////////
  //Update Jobday//
  const updateFormJobday = (e) => {
    setWritedb(true)
    const fieldvalue = e.target.value;
    fetch("https://node.vrealism.com:5000/update/project/jobday", {
      method: "POST",
      body: JSON.stringify({
        fieldvalue,
        idproj,
      }),
      headers: { "Content-Type": "application/json" },
    })
    .then((res) => res.json())
    .then((json) =>{
    
      const dateback = json[0].proj_dateAssigned;
      const dateformat = new Date(dateback);     
      const utc = new Intl.DateTimeFormat("fr-CA",{
        timeZone:"UTC",
        day:'2-digit',
        month:'2-digit',
        year:'numeric',  
        }).format(dateformat)    
      
      setJobday(utc);
      setTimeout(()=>{
        setWritedb(false);
      },2000)
      
    /*   inputRefStorage.current.style.color = "white";  */
    } );
  };
  //Update Jobday//
  ///////////////////////////////////////////////
  //Update sethour//
  const updateFormSethour = (e) => {
    setWritedb(true)
    const fieldvalue = e.target.value;
    fetch("https://node.vrealism.com:5000/update/project/sethour", {
      method: "POST",
      body: JSON.stringify({
        fieldvalue,
        idproj,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) =>{
        
        setTimeout(()=>{
          setWritedb(false);
        },2000)
     /*    inputRefStorage.current.style.color = "white"; */
      });
  };
  //Update sethour//
  //////////////////////////////////////////////

  //Update Project address//
  const updateFormAddress = (e) => {
    setWritedb(true)
    const fieldvalue = e.target.value;
    fetch("https://node.vrealism.com:5000/update/project/setaddress", {
      method: "POST",
      body: JSON.stringify({
        fieldvalue,
        idproj,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) =>{
        setTimeout(()=>{
          setWritedb(false);
        },1700)
        
      }) 
  };
  // Update Project address//
  /////////////////////////////////////////////
  //Update homeOwner//
  const updateFormHomeowner = (e) => {
    setWritedb(true)
    setHomeownername(e.target.value);
    const fieldvalue = e.target.value;
    fetch("https://node.vrealism.com:5000/update/project/sethomeowner", {
      method: "POST",
      body: JSON.stringify({
        fieldvalue,
        idproj,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) =>{

       setTimeout(()=>{
        setWritedb(false);
       },1700)
        
      } );
  };
  //Update homeOwner//
  ////////////////////////////////////////////
  //Update homeOwnerPhone//
  const updateFormHomeownerphone = (e) => {
    setWritedb(true)
    const fieldvalue = e.target.value;
    fetch("https://node.vrealism.com:5000/update/project/sethomeownerphone", {
      method: "POST",
      body: JSON.stringify({
        fieldvalue,
        idproj,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) =>{
        setTimeout(()=>{
          setWritedb(false);
         },1700)      
      }      
      )
  };
  //Update homeOwnerPhone//
  ////////////////////////////////////////////
  //Update homeOwnerPhone//
  const updateFormHomeownermail = (e) => {
    setWritedb(true)
    const fieldvalue = e.target.value;
    fetch("https://node.vrealism.com:5000/update/project/sethomeownermail", {
      method: "POST",
      body: JSON.stringify({
        fieldvalue,
        idproj,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) =>{
        setTimeout(()=>{
          setWritedb(false);
         },1700) 
      });
  };
  //Update homeOwnerPhone//
  ///////////////////////////////////////////////
  //Update contractor//
  const updateFormContractor = (e) => {
    setWritedb(true)
    const fieldvalue = e.target.value;
    fetch("https://node.vrealism.com:5000/update/project/contractor", {
      method: "POST",
      body: JSON.stringify({
        fieldvalue,
        idproj,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) =>{

        setTimeout(()=>{
          setWritedb(false);
        },1700)
      } );
  };
  //Update contractor//

  ///////////////////////////////////////////////

  //Update Details of project//////

  const updateFormDetails =  (e)=>
  {
    e.preventDefault()
    if(!details){
      alert("Please complete de project's details")
    }
    else
    {
    setWritedb(true)
    fetch("https://node.vrealism.com:5000/update/details", {
      method: "POST",
      body: JSON.stringify({
        details,
        idproj,
      }),
      headers: { "Content-Type": "application/json" },
    })
    .then((ress)=>ress.json())
    .then((json)=>{
      setTimeout(()=>{

        setWritedb(false);


      },1700)
      
    })
      
    }
  
  }


  //Update Details of project//////

  ///////////////////////////////////////////////
  ///////////////UPDATE SECTION ROOT FORM//////////////////////

  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

  //*********************************************************** //
  /////////////////USEEFFECTS//////////////////////////////////////
  //*********************************************************** //
  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

  //Bring all table project´s_services by projid//
  useEffect(() => {
    fetch("https://node.vrealism.com:5000/api/getservbyid", {
      method: "POST",
      body: JSON.stringify({
        idproj,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        setServices(res);
      });
  }, []);
  //Bring all table project´s_services by projid//

  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

  //Bring all services table//
  useEffect(() => {
    fetch("https://node.vrealism.com:5000/api/servicestable")
      .then((res) => res.json())
      .then((res) => {
        setServicestable(res);
      });
  }, []);
  //Bring all services table//

  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

  //Bring all project_file´s table by projid//
  useEffect(() => {
    fetch("https://node.vrealism.com:5000/api/getprojfilesbyid", {
      method: "POST",
      body: JSON.stringify({
        idproj,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        setProjectfolders(res);
      });
  }, [idproj]);
  //console.log(projectfolders);
  //Bring all project_file´s table by projid//

  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

  //Bring all Vrealism´s surveryors//
  useEffect(() => {
    fetch("https://node.vrealism.com:5000/api/surveyors")
      .then((res) => res.json())
      .then((res) => {
        setSurveyors(res);
      });
  }, [contractor]);
  //Bring all Vrealism´s suveryors//
                  //
  //Filter suveyor's list excluding the current suveyor//
  useEffect(()=>{
    const fillfilter = surveyors.filter(el=> el.cli_name != surveyor)
    setFiltersurveyor(fillfilter);    
   },[surveyors])
  //Fiter suveyor's list excluding the current suveyor//



  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //
  //*********************************************************** //
  //Bring all Vrealism´s architecs//
  useEffect(() => {
    fetch("https://node.vrealism.com:5000/api/architecs")
      .then((res) => res.json())
      .then((res) => {
        setArchitecs(res);
      });
  }, [contractor]);
  //Bring all Vrealism´s architecs//
                 //
  //Filter architec's list excluding the current architec//


  useEffect(()=>{
    const fillfilter = architecs.filter(el=> el.cli_name != architec)
    setFilterarchitec(fillfilter);
   },[architecs])


  //Filter architec's list excluding the current architec//

  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

  //Bring all chats by projid//
  useEffect(() => {
    fetch("https://node.vrealism.com:5000/api/getvrnotes", {
      method: "POST",
      body: JSON.stringify({
        idproj,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        const data = res.map((items) => ({
          id: items.id,
          client: clients.find((el) => items.id_client === el.id),
          sms: items.sms,
        }));
        setChats(data);
      });
  }, [currentclient]);
  //Bring all chats by projid//
  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //
  //Bring all clients by Company//
/*   useEffect(() => {
    const company = projecthouseownername;
    fetch("https://node.vrealism.com:5000/bringClient", {
      method: "POST",
      body: JSON.stringify({
        company,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => setClientsbycompany(res));
  }, [projecthouseownername]); */
  //Bring al  clients by Company//

  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

   // Bring clients and independents if exists //
    useEffect(()=>{

      const company = projecthouseownername;
    
      fetch("https://node.vrealism.com:5000/bringClient",{
      method: "POST",
      body: JSON.stringify({
        company,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        const data = res.map((items) => ({
          name: items.cli_name,
          id: items.cli_id,
        }));

      fetch("https://node.vrealism.com:5000/bringClientsAndIndependents",{
            method: "POST",
            body: JSON.stringify({
              company,
            }),
            headers: { "Content-Type": "application/json" },
          })
          .then((res)=>res.json())
          .then((json)=>{

            const dita = json.map((items) => ({
              name: items.name,
              id: items.client_id,
            }));
            setClientsbycompany([...data, ...dita]);
              
          })       
      });

    },[projecthouseownername])  
   // Bring clients and independents if exists //

   //Fiter contractor list exclude the current contractor //
   useEffect(()=>{
    const fillfilter = clientsbycompany.filter(el=> el.name != contractor)
    setFiltercontractor(fillfilter);
   },[clientsbycompany])
   //Fiter contractor list exclude the current contractor //

    //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //
  //Send sms from vrnotes//
  const submitsms = (e) => {
    e.preventDefault();
    if (vrnotes === "") {
      alert("text a sms");
      return false;
    }
    setWritedb(true);
    fetch("https://node.vrealism.com:5000/chat/vrnotes", {
      method: "POST",
      body: JSON.stringify({
        idproj,
        vrnotes,
        currentclient,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) => {
        const data = json.map((items) => ({
          id: items.id,
          client: clients.find((el) => items.id_client === el.id),
          sms: items.sms,
        }));
        setChats(data);
        setVrnotes("");
        setTimeout(()=>{
          setWritedb(false);
        },1700)
      });
  };
  //Send sms from vrnotes//
  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //
  //ONLOAD INFO AND UPDATE CURRENT SERVICES //
  useEffect(() => {
    const checkeador = (ex) => {
      if (services.find((ese) => ese.services_serv_id === ex.id)) {
        return true;
      } else {
        return false;
      }
    };
    const foun = checkboxes.map((item) => ({
      id: item.id,
      label: item.item,
      checked: checkeador(item),
      clientcliid: clientcliid,
    }));

    setChekes(foun);
  }, [services]);
  //ONLOAD INFO AND UPDATE CURRENT SERVICES //
  //*********************************************************** //

  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

  //BRING ALL INVOICE´S TABLE //
  //Bring the invoice information//
  useEffect(() => {
    fetch("https://node.vrealism.com:5000/api/getinvoice", {
      method: "POST",
      body: JSON.stringify({
        idproj,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {       
        setInvoicestatus(res[0].inv_status);
        setInvoicelink(res[0].linkapp);
        setWaveappid(res[0].num_invoice);
        setWaveapplink(res[0].linkapp);
        setWaveappstatus(res[0].inv_status);
      });
  }, []);
   /* setInvoiceid(res[0].num_invoice); */ 
  //Bring the invoice information//
  //BRING ALL INVOICE´S TABLE //
   
  
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

  /////////////////USEEFFECTS//////////////////////////////////////
  //*********************************************************** //
  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

  /////////////////SEPARATOR//////////////////////////////////////
  //*********************************************************** //

  //*********************************************************** //
  /////////////////RENDER RETURN////////////////////////////////
  //*********************************************************** //
  return (
    <Container style={{ marginTop: "20px"}}>
{/* 
         <Form.Group 
          style={{zIndex:'99999',
          backgroundColor:'gray',
          borderRadius:'10px 10px 10px 10px',
          display:'flex',
          border:'3px green dotted'
          }}
          className="sticky-top"
        >

            <Form.Group 
             style={{
             marginLeft:'50px',
             width:'auto',
             marginRight:'500px', 
             paddingTop:'5px'}}>                              
             <p  
             style={{
              fontWeight: "bold",
              color: "white",
              fontSize: "30px",
              }}>
              Current details
            </p>
            </Form.Group>
            <Form.Group style={{display:'flex',  width:'350px', justifyContent:'space-around'}}>

                   <Form.Group style={{display:'flex', flexDirection:'column', alignItems:'center', marginTop:'5px'}}>
                    <a href="#">
                    <InfoIcon
                      style={{
                      fontSize:'30px',
                      color:'orange',
                      paddingTop:'5px'
                    }}
                    onClick={()=>{
                      navigate("/info");
                    }}
                    />
                    </a>  
                    <a href="#" style={{textDecoration:'none'}}>
                    <small 
                      style={{color:"white"}} 
                      onClick={()=>{
                        navigate("/info");
                      }}>
                      Details
                    </small>
                    </a>                           
                  </Form.Group>

                  <Form.Group style={{display:'flex', flexDirection:'column', alignItems:'center' , marginTop:'5px'}}>
                        <a href="#">
                                    <ListAltIcon
                                    style={{
                                      fontSize:'30px',
                                      paddingTop:'5px',
                                      color: "aqua" ,
                                      }}
                                      onClick={() => {
                                        auth.setIdcheck(parseInt(window.localStorage.getItem('projectid'))); 
                                        navigate("/");
                                    }}
                                      />
                        </a>
                        <a href="#" style={{textDecoration:'none'}}>
                                    <small style={{color:"white"}} 
                                      onClick={() => {
                                        auth.setIdcheck(parseInt(window.localStorage.getItem('projectid'))); 
                                        navigate("/");
                                    }}
                                    >
                                      List
                                    </small>
                        </a>
                  </Form.Group>

                  <Form.Group style={{display:'flex', flexDirection:'column', alignItems:'center' , marginTop:'5px'}}>
                    <StorageIcon style={{fontSize:'30px' , paddingTop:'5px'}} ref={inputRefStorage}/>
                      {writedb?
                      <small style={{color:"#46f509"}}>
                      Writing DB...
                      </small>:
                      <small style={{color:"white"}} ref={inputRefStorageGreen}>
                      Storage
                      </small>   
                      }                                       
                 </Form.Group>

            </Form.Group>
         
        </Form.Group> */}


      <div className="initc">
       {loading === true ? (
          <Container
            style={{
              width: "auto",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <ThreeDots color="orange" height={420} width={80} />
          </Container>
        ) : (
          <Form className="initc-container">
            {/*-------------------------------------------------------------------------*/}
            {/* FORM DB  CURRENT */}
          
            <Form.Group style={{display:'flex',marginTop:'10px'}}>
                          
              <p style={{color: "white",fontSize: "30px",fontWeight: "bold",marginLeft:'50px',marginTop:'40px'}}>
                  Current details
                  <InfoIcon style={{padding:"4px",color:"orange",marginLeft:"10px",marginTop: "6px"}}/>
                  <small style={{ fontSize: "10px"}}>
                  Check and update the project current details
                  </small>
              </p>
                   
     
     
               <Form.Group 
                  style={{
                      zIndex:'9999',
                      backgroundColor:'#008088',
                      borderRadius:'25px',
                      display:'flex',
                      opacity:'0.9',
                      position:'fixed',
                      marginLeft:'67%',
                      marginTop:'20px',
                      padding:'10px',
                      flexDirection:'column',
                      alignItems:'center',
                     /*  boxShadow: "2px 2px 2px white", */
                  }}
                  >
                <Form.Group style={{display:'flex',justifyContent:'space-around',paddingLeft:'20px',paddingRight:'10px'}}>
     
                    <Form.Group style={{display:'flex', flexDirection:'column', alignItems:'center', paddingRight:'20px'}}>
                                                          <a href="#" title="Project details">                           
                                                            <InfoIcon                                                                 
                                                                style={{
                                                                fontSize:'30px',
                                                                color:'white',
                                                              }}
                                                              onClick={()=>{
                                                                auth.setIdcheck(parseInt(window.localStorage.getItem('projectid'))); 
                                                                navigate("/info");
                                                              }}
                                                            />
                                                          </a>  
                                                          {/*  <a href="#" style={{textDecoration:'none',marginTop:'-5px'}}>
                                                          <small 
                                                            style={{color:"white"}} 
                                                            onClick={()=>{
                                                              navigate("/edit");
                                                            }}>
                                                            Edit
                                                          </small>
                                                          </a> */}
                    </Form.Group>
     
                    <Form.Group style={{display:'flex', flexDirection:'column', alignItems:'center' , paddingRight:'20px'}}>
                      <a href="#" title="Return list">
                       <ListAltIcon
                        style={{
                        fontSize:'30px',
                        /*  paddingTop:'5px', */
                        color: "white" ,
                      }}
                      onClick={(e) => {
                      e.preventDefault();
                      auth.setIdcheck(parseInt(window.localStorage.getItem('projectid')));            
                      navigate("/");
                        }}
                        />
                      </a>
                    {/*   <a href="#" style={{textDecoration:'none', marginTop:'-5px'}}>
                    <small style={{color:"white"}} 
                    onClick={() => {   
                    auth.setIdcheck(idproj);                              
                    navigate("/");
                    }}
                    >
                    List
                    </small>
                    </a> */}
                    </Form.Group>
     
                    <Form.Group style={{display:'flex', flexDirection:'column', alignItems:'center' , paddingRight:'10px'}}>

                          
                          
                          {writedb === true
                           ?
                           <TailSpin color="orange" height={30} width={30} style={{marginTop:'-30px'}} />
                           :
                           <StorageIcon
                           style={{
                             fontSize:'30px',
                             color:'white',
                           }} 
                          /*  ref={inputRefStorage} */
                        />
                        }         
                          
                                                                         
                    </Form.Group>
     
     
                </Form.Group>


              {writedb === true
              ?
              <small style={{color:"yellow", marginTop:'10px', fontWeight:'bold'}}>Writing changes ...</small>
              :
              <small style={{color:"white"}} ref={inputRefStorageGreen}>
              
              </small>   
            } 

                           
              </Form.Group>
                             
            </Form.Group>

             <hr
              style={{
                color: "white",
                width: "1200px",
                marginLeft: "50px",
                marginTop:'-10px',
              }}
            />           



            {form.map((el) => (
              <>
                {/*  */}
                {/*------------------------------SEPARATOR-------------------------------------------*/}
                {/*  */}
                <div className="firstrow">
                  <Form.Group className="mb-1 firstFormGroup">
                    <Form.Label style={{ color: "white" }}>
                      Project Id
                    </Form.Label>
                    <Form.Control
                      key={el.proj_id}
                      style={{
                        width: "100px",
                        textAlign: "center",
                      }}
                      type="text"
                      onChange={(e)=>e.preventDefault()}
                      value={el.proj_id}
                    />
                  </Form.Group>
                  <Form.Group className="mb-1 firstFormGroup" style={{display:'flex', flexDirection:'column'}}>
                    <Form.Label style={{color:"white",width:"200px"}}>
                    <small> Job Day</small>
                   {!jobday && 
                    <small style={{color:'orange',fontSize:'10px',marginLeft:'20px'}}>
                      Pending
                    </small>
                   }
                    </Form.Label>
                   <Form.Control
                      type="date"                                      
                      value={jobday} 
                      onChange={(e) => {
                        setJobday(e.target.value);
                        updateFormJobday(e);
                      }}
                   />                                 
                  </Form.Group>
                  <Form.Group className="mb-1 firstFormGroup">
                    <Form.Label style={{ color:"white"}}>
                      <small>Time</small>
                      {time === "00:00:00"
                       ?
                      <small  style={{color:'orange',fontSize:'10px',marginLeft:'20px'}}>
                        Pending
                      </small>
                       :null
                      }
                    </Form.Label>
                    <Form.Control
                      key={el.proj_id}
                      style={{ width: "160px"}}
                      type="time"
                      value={time}
                      onChange={(e) =>{
                        setTime(e.target.value)
                        updateFormSethour(e);
                      } }
                       onMouseUp={(e) =>{
                      /*   inputRefStorage.current.style.color = "#46f509" */
                        /* inputRefStorageGreen.current.innerHTML = "Writing field ..." */
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-1 firstFormGroup">
                    <Form.Label
                      style={{
                        color: "white",
                        width: "90px",
                        fontWeight: "bold",
                      }}
                    >
                      Sqft
                      {!sqft && <small style={{color:'orange' , fontSize:'10px', marginLeft:'10px'}}>
                        Pending
                        </small>}
                    </Form.Label>
                    <Form.Control
                      key={el.proj_id}
                      style={{
                      width: "90px",
                      textAlign: "center",
                      paddingRight:'30px'
                      }}
                      type="text"
                      value={sqft}
                      onChange={(e) => {
                       /*  inputRefStorage.current.style.color = "#46f509"; */
                        setSqft(e.target.value);
                        updateFormSqft(e);                        
                      }}
                      onBlur={(e) => {
                        updateFormSqft(e);
                      }}
                      onMouseUp={(e) =>{
                       /*  updateFormSqft(e); */
                       /*  inputRefStorage.current.style.color = "#46f509" */
                       /*  inputRefStorageGreen.current.innerHTML = "Writing field ..." */
                      }                  
                      }
                      ref={inputRefSqft}
                      onKeyDown={(e)=>updateFormSqft(e)}
                    />
                    {sqft &&
                    <a href="#" title="clear">
                    <DeleteForeverIcon 
                       style={{
                       position:'absolute',
                       zIndex:'9999', 
                       color:'red', 
                       marginLeft:'62px', 
                       marginTop:'-30px'
                       }}
                       onClick={eraseSqft}                       
                    >
                    </DeleteForeverIcon>
                    </a>
                    }
                    
                  </Form.Group>
                  <Form.Group className="mb-1 firstFormGroup">
                    <Form.Label
                      style={{
                        color: "white",
                        width: "150px",
                        fontWeight: "bold",
                      }}
                    >
                      Contractor
                    </Form.Label>
                    <Form.Select
                      style={{ width: "160px" }}
                      onChange={(e) => updateFormContractor(e)}
                    >
                    
                    <option style={{ color: "#aeb1b4" }}>{contractor}</option>


                    {filtercontractor &&
                      filtercontractor.map((el)=>
                      (
                        <option value={el.id}>{el.name}</option>
                      ))
                    }

                  {/*   {clientsbycompany.map((el)=>(
                      <option value={el.id}>{el.name}</option>
                    ))}  */}
                    
                    
                    </Form.Select>
                  </Form.Group>                  
                </div>
                {/*  */}
                {/*------------------------------SEPARATOR-------------------------------------------*/}
                {/*  */}
                <div className="secondrow">
                  <Form.Group className="mb-1 secondFormGroup">
                    <Form.Label
                      style={{
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      Project Address
                    {!homeaddress &&
                    <small
                      style={{
                      marginLeft: "20px",
                      color: "orange",
                      fontSize:'10px',
                    }}
                    >
                    Pending
                    </small>                   
                    }                     
                    </Form.Label>
                    <Form.Control                     
                         style={{
                         width: "500px",
                         textAlign: "left",
                         }}
                         key={el.proj_id}
                         type="text"
                         onChange={(e) => RenderAddress(e)} 
                         onBlur={(e) => updateFormAddress(e)}
                         /* onMouseUp={(e) => RenderAddress(e)} */
                        /*  onMouseUp={(e) => updateFormAddress(e)}  */
                         value={homeaddress}
                         ref={inputRef}
                        /*  onKeyDown={(e)=>updateFormAddress(e)}  */                        
                     />
                     {homeaddress &&
                     <a href="#" title="clear">
                     <DeleteForeverIcon 
                        style={{
                        position:'absolute',
                        zIndex:'999', 
                        color:'red', 
                        marginLeft:'470px', 
                        marginTop:'-30px'
                        }}
                        onClick={()=>eraseAddress()}
                     />
                    
                     </a>
                     }
                  </Form.Group>
                  <Form.Group className="mb-1 secondFormGroup">
                    <Form.Label
                      style={{
                        color: "white",
                        width: "90px",
                        fontWeight: "bold",
                      }}
                    >
                      State
                    </Form.Label>
                    <Form.Control
                      key={el.proj_id}
                      style={{
                        width: "90px",
                        textAlign: "center",
                      }}
                      type="text"
                      value={homeaddress && state}
                      onChange={()=>{}}
                    />
                  </Form.Group>
                  <Form.Group className="mb-1 secondFormGroup">
                    <Form.Label
                      style={{
                        color: "white",
                        width: "150px",
                        fontWeight: "bold",
                      }}
                    >
                      City
                    </Form.Label>
                    <Form.Control
                      key={el.proj_id}
                      style={{
                        width: "150px",
                        textAlign: "center",
                      }}
                      type="text"
                      value={homeaddress && city}
                      onChange={()=>{}}
                    />
                  </Form.Group>
                  <Form.Group className="mb-1 secondFormGroup">
                    <Form.Label
                      style={{
                        color: "white",
                        width: "80px",
                        fontWeight: "bold",
                      }}
                    >
                      ZipCode
                    </Form.Label>
                    <Form.Control
                      key={el.proj_id}
                      style={{
                        width: "80px",
                        textAlign: "center",
                      }}
                      type="text"
                      value={homeaddress && zipcode}
                      onChange={()=>{}}
                    />
                  </Form.Group>
                </div>
                {/*  */}
                {/*------------------------------SEPARATOR-------------------------------------------*/}

                {/*  */}
                <div className="fourthrow">
                  <Form.Group className="mb-1 thirdFormGroup">
                    <Form.Label
                      style={{
                        color: "white",
                        width: "300px",
                        fontWeight: "bold",
                      }}
                    >
                      Homeowner name
                    {!homeownername &&
                    <small
                      style={{
                      marginLeft: "20px",
                      color: "orange",
                      fontSize:'10px',
                    }}
                    >
                    Pending
                    </small>                   
                    }  
                    </Form.Label>
                    <Form.Control
                      key={el.proj_id}
                      style={{
                      width:"300px",
                      textAlign:"left",
                      }}
                      type="text"
                      onChange={(e)=>
                        {
                        setWritedb(true);
                        setHomeownername(e.target.value);
                        
                        }
                      }
                      onBlur={(e)=>updateFormHomeowner(e)}
                      onMouseUp={(e) =>
                        {
                         /*  inputRefStorage.current.style.color = "#46f509" */
                         /*  inputRefStorageGreen.current.innerHTML = "Writing field ..."
                          updateFormHomeowner(e) */
                        }
                      }
                      onKeyDown={(e)=>updateFormHomeowner(e)}
                      value={homeownername}
                      ref={inputRefHomeOwner}
                    />
                    {homeownername &&
                    <a href="#" title="clear">
                    <DeleteForeverIcon 
                       style={{
                       position:'absolute',
                       zIndex:'999', 
                       color:'red', 
                       marginLeft:'270px', 
                       marginTop:'-30px'
                       }}
                       onClick={eraseHomeName}                       
                    >
                    </DeleteForeverIcon>
                    </a>                    
                    }
                    
                  </Form.Group>
                  <Form.Group className="mb-1 thirdFormGroup">
                    <Form.Label
                      style={{
                        color: "white",
                        width: "200px",
                        fontWeight: "bold",
                      }}
                    >
                      Homeowner phone
                    {!homeownerphone &&
                    <small
                      style={{
                      marginLeft: "10px",
                      color: "orange",
                      fontSize:'10px',
                    }}
                    >
                    Pending
                    </small>                   
                    } 
                    </Form.Label>
                    <Form.Control
                      key={el.proj_id}
                      style={{
                      width: "200px",
                      textAlign: "left",
                      }}
                      type="text"
                      onChange={(e) =>{
                     /*  inputRefStorage.current.style.color = "#46f509"; */
                      setHomeownerphone(e.target.value)
                      } }

                      onBlur={(e) => updateFormHomeownerphone(e)}
                     /*  
                      onMouseUp={(e) =>{
                        inputRefStorageGreen.current.innerHTML = "Writing field ..."
                        updateFormHomeownerphone(e);
                      }} */
                      onKeyDown={(e)=>updateFormHomeownerphone(e)}
                      value={homeownerphone}
                      ref={inputRefPhoneOwner}
                    />
                    {homeownerphone && 
                    <a href="#" title="clear">
                    <DeleteForeverIcon 
                       style={{
                       position:'absolute',
                       zIndex:'999', 
                       color:'red', 
                       marginLeft:'150px', 
                       marginTop:'-30px'
                       }}
                       onClick={eraseHomePhone}                       
                    >
                    </DeleteForeverIcon>
                    </a>                   
                    }                    
                  </Form.Group>
                  <Form.Group className="mb-1 thirdFormGroup">
                    <Form.Label
                      style={{
                        color: "white",
                        width: "300px",
                        fontWeight: "bold",
                      }}
                    >
                      Homeowner Email
                      {!homeowneremail &&
                    <small
                      style={{
                      marginLeft: "10px",
                      color: "orange",
                      fontSize:'10px',
                    }}
                    >
                    Pending
                    </small>                   
                    } 
                    </Form.Label>
                    <Form.Control
                      key={el.proj_id}
                      style={{
                      width: "300px",
                      textAlign: "left",
                      }}
                      type="text"
                      onChange={(e) =>{
                        setHomeowneremail(e.target.value)
                      }}
                      onBlur={(e) => updateFormHomeownermail(e)}
                     /*  onMouseUp={(e) =>
                        {
                          inputRefStorageGreen.current.innerHTML = "Writing field ..."
                        }
                      } */
                      value={homeowneremail}
                      ref={inputRefMailOwner}
                      onKeyDown={(e)=>updateFormHomeownermail(e)}
                    />
                    {homeowneremail &&
                    <a href="#" title="clear">
                    <DeleteForeverIcon 
                       style={{
                       position:'absolute',
                       zIndex:'999', 
                       color:'red', 
                       marginLeft:'270px', 
                       marginTop:'-30px'
                       }}
                       onClick={eraseHomeMail}                       
                    >
                    </DeleteForeverIcon>
                    </a>                   
                    }
                    
                  </Form.Group>
                </div>
                {/*  */}

                {/*-------------------------------------------------------------------------*/}

                {/*-------------------------------------------------------------------------*/}

                {/*-------------------------------------------------------------------------*/}
                {/*  */}
              </>
            ))}
            {/* FORM DB  CURRENT */}
            {/*  */}
            {/*-------------------------------------------------------------------------*/}
            {/*  */}


            <Form.Group style={{display:'flex'}}>
            <p 
              style={{
                color: "white",
                fontSize: "30px",
                fontWeight: "bold",
                marginLeft:'50px',
                marginTop:'100px'
                }}>
                  Current services
                  <InfoIcon style={{padding:"4px",color:"orange",marginLeft:"10px",marginTop: "6px"}}/>
                  <small style={{ fontSize: "10px"}}>
                  Check and update the project current services and details
                  </small>
              </p> 


            </Form.Group>
            <hr
              style={{
                color: "white",
                width: "1200px",
                marginLeft: "50px",
                marginTop: "-10px",
              }}
            />
            {/*  */}
            {/*-----------------------SERVICES-------------------------------------------*/}
            {/*  */}
            <div className="projdesc">
              <Form.Group>
                {chekes.map((el) => (
                  <Form.Check
                    style={{ fontSize: "20px" }}
                    key={el.id}
                    type="checkbox"
                    label={el.label}
                    value={el.label}
                    checked={el.checked}
                    id={el.label}
                    /*onChange={(e) => handleCheck(el)}*/
                    onChange={() => flashhang(el)}
                  />
                ))}
              </Form.Group>
              <Form.Group
                className="mb-3"
                style={{
                  /* border: "dotted 1px black", */
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Form.Group>
                  <Form.Label
                    style={{
                      width: "400px",
                      marginLeft: "50px",
                      marginTop: "20px",
                      color: "white",
                      fontSize: "20px",
                    }}
                  >
                    Details
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    style={{ width: "400px", marginLeft: "50px" }}
                    value={details}
                    onChange={(e) =>
                      {
                        setDetails(e.target.value);                  
                      }}
                    onBlur={(e)=>updateFormDetails(e)}
                  />
                </Form.Group>
                <Form.Group
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <a href="#" title="clean"> 
                  <CleaningServicesIcon                   
                    style={{color:'yellow', fontSize:'40px', marginTop:'5px'}}
                    onClick={(e) => {
                      e.preventDefault();
                      setDetails("");
                    }}                    
                  />                  
                  </a>                 
                  <Button                   
                    variant="outline-warning"
                    style={{ marginLeft: "2px", marginTop:'5px' }} 
                    onClick={(e)=>updateFormDetails(e)} 
                    onKeyDown={(e)=>updateFormDetails(e)}
                  >
                    Set details
                  </Button>
                </Form.Group>
              </Form.Group>
            </div>

            {/*  */}
            {/*-------------------------------------------------------------------------*/}
            {/*  */}

            {auth.type === "superadmin" ? (

            <Form.Group style={{display:'flex'}}>
            <p 
               style={{
                marginTop: "100px",
                fontWeight: "bold",
                marginLeft: "53px",
                color: "white",
                fontSize: "30px",
              }}>
                  Vrealism info
                  <InfoIcon style={{padding:"4px",color:"orange",marginLeft:"10px",marginTop: "6px"}}/>
                  <small style={{ fontSize: "10px"}}>
                  Check and update the project current status, surveyor and architec
                  </small>
              </p> 


            </Form.Group>
           
            ) : (
              <Form.Group style={{display:'flex'}}>
              <p 
                 style={{
                  marginTop: "100px",
                  fontWeight: "bold",
                  marginLeft: "53px",
                  color: "white",
                  fontSize: "30px",
                }}>
                    Project status info
                    <InfoIcon style={{padding:"4px",color:"orange",marginLeft:"10px",marginTop: "6px"}}/>
                    <small style={{ fontSize: "10px"}}>
                    Check the project status info
                    </small>
                </p>  
              </Form.Group>
            )}
            <hr
              style={{
                color: "white",
                width: "1200px",
                marginLeft: "50px",
                marginTop: "-10px",
              }}
            />
            {/*  */}
            {/*-------------------------------------------------------------------------*/}
            {/*  */}
            <div className="seventh">
              <Form.Group
                style={{
                  width: "200px",
                  height: "300px",
                  /*   border: "dotted 1px white", */
                  marginLeft: "40px",
                  marginTop: "15px",
                }}
              >
                <Form.Label
                  style={{
                    width: "300px",
                    marginLeft: "30px",
                    marginTop: "25px",
                    color: "white",
                    fontSize: "20px",
                  }}
                >
                  Current status
                </Form.Label>
                {/* Render status by type of client and conditional if it have*/}
                {auth.type === "superadmin" ? (
                  statuslist.map((rec) => (
                    <Form.Group
                      style={{ marginLeft: "30px", marginTop: "5px" }}
                    >
                      <Form.Check
                        id={rec.current}
                        key={rec.id}
                        label={rec.current}
                        /*  value={rec.id} */
                        style={{
                          color: "white",
                          marginLeft: "1px",
                          fontSize: "20px",
                        }}
                        type="switch"
                        checked={
                          rec.current === status ? !rec.checked : rec.checked
                        }
                        onChange={() => {
                          handleSwitch(rec);
                        }}
                      />
                    </Form.Group>
                  ))
                ) : (
                  <h5
                    style={{
                      marginLeft: "38px",
                      marginTop: "5px",
                      color: "rgb(64, 192, 243)",
                      fontSize: "18px",
                    }}
                  >
                    {status}
                  </h5>
                )}
              </Form.Group>
              {/*  */}
              {/* Render status by type of client and conditional if it have*/}
              <Form.Group
                style={{ /* border: "dotted 1px white",  */ width: "500px" }}
              >
                <Form.Group
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "initial",
                    justifyContent: "initial",
                    justifyItems: "initial",
                    justifyContent: "initial",
                    /*  border: "dotted 5px red", */
                    marginTop: "39px",
                  }}
                >
                  {auth.type === "superadmin" ? (
                    <>
                      <Form.Group
                        style={{
                          width: "240px",
                        }}
                      >
                        <Form.Label
                          style={{
                            width: "auto",
                            marginLeft: "0px",
                            marginTop: "0px",
                            color: "white",
                            fontSize: "20px",
                          }}
                        >
                          Surveyor        
                        </Form.Label>


                        <Form.Select style={{ width: "200px", marginLeft: "0px" }} onChange={(e) => updateSurveyor(e)}>

                            <option value={surveyor} style={{ color: "#aeb1b4" }}>{surveyor}</option>

                         {/*    {surveyors.map((el) => (
                              <option value={el.cli_name}>{el.cli_name}</option>
                            ))}
                           */}


                           {filtersurveyor.map((el) => (
                              <option value={el.cli_name}>{el.cli_name}</option>
                            ))}

                           


                            <option value="Remenber">Remenber</option>
                        </Form.Select>




                      </Form.Group>
                      <Form.Group style={{width: "240px"}}>
                        <Form.Label
                          style={{
                            width: "auto",
                            marginLeft: "0px",
                            marginTop: "0px",
                            color: "white",
                            fontSize: "20px",
                          }}
                        >
                          Architec
                        </Form.Label>

                        <Form.Select style={{ width: "200px", marginLeft: "0px" }} onChange={(e) => updateArchitec(e)}>

                        <option value={architec} style={{ color: "#aeb1b4" }}>{architec}</option>

                          {/*    {surveyors.map((el) => (
                            <option value={el.cli_name}>{el.cli_name}</option>
                          ))}
                          */}

                            {filterarchitec.map((el) => (
                              <option value={el.cli_name}>{el.cli_name}</option>
                            ))}

                          <option value="Remenber">Remenber</option>
                          </Form.Select>
                      </Form.Group>
                    </>
                  ) : null}
                  {/* select surveyor and architec zone */}
                </Form.Group>
                <Form.Group>
                  {auth.type === "superadmin" ? (
                    <div className="eigth">
                      <Form.Label
                        style={{
                          width: "300px",
                          marginLeft: "0px",
                          marginTop: "20px",
                          color: "white",
                          fontSize: "20px",
                        }}
                      >
                        Vrealism Notes
                      </Form.Label>                  

                      <TextAdminArea chats={chats}/>            

                      <Form.Control
                        type="text"
                        style={{ width: "450px", marginTop:'5px', borderRadius:'10px' }}
                        placeholder="Send a note ..."
                        onChange={(e) => setVrnotes(e.target.value)}
                        value={vrnotes}
                      />
                      <Button
                        onClick={(e) => submitsms(e)}
                        variant="outline-primary"
                        style={{ marginTop: "-68px", marginLeft: "390px" }}
                      >
                        Send
                      </Button>
                    </div>
                  ) : null}
                </Form.Group>
              </Form.Group>
              {/*select surveyor and architec zone  */}
            </div>
            {/*  */}
            {/*-------------------------------------------------------------------------*/}
            {/*  */}
            {auth.type === "superadmin" ? (
              <>
               
                <Form.Group style={{display:'flex'}}>
            <p 
               style={{
                fontWeight: "bold",
                    marginTop: "100px",
                    marginLeft: "60px",
                    color: "white",
                    fontSize: "30px",
              }}>
                  Links router
                  <InfoIcon style={{padding:"4px",color:"orange",marginLeft:"10px",marginTop: "6px"}}/>
                  <small style={{ fontSize: "10px"}}>
                   Paste and save the link in each category
                  </small>
              </p> 


            </Form.Group>
                <hr
                  style={{
                    color: "white",
                    width: "1200px",
                    marginLeft: "60px",
                    marginTop: "-10px",
                  }}
                />
                {/*  MULTISECTION FOR PASTE LINKS */}
                {/*Matterport Link */}

                <div className="nineth">
                  <Form.Group>
                    <Form.Label
                      style={{
                        width: "200px",
                        marginLeft: "70px",
                        marginTop: "85px",
                        color: "white",
                        fontSize: "20px",
                      }}
                    >
                      Matterport Link
                    </Form.Label>
                    <Form.Control
                      style={{
                        width: "700px",
                        textAlign: "left",
                        marginLeft: "250px",
                        marginTop: "-40px",
                      }}
                      type="text"
                      value={linkmatterport}
                      onChange={(e) =>
                        {
                        setLinkmatterport(e.target.value);   
                      } }
                    />
                  </Form.Group>

                  <a href="#" title="Save link"  onClick={(e) =>{linkmatterContainer(e)} }>
                    <AddCircleOutlinedIcon
                      role="button"
                      id="matterport"
                      style={{
                        marginTop: "90px",
                        marginLeft: "5px",
                        color: "rgb(64, 192, 243)",
                      }}                   
                    />
                  </a>
                  <a href="#" title="Clear"  onClick={(e)=>clearmatterlinkSpace(e)}>
                  <CleaningServicesIcon
                    role="button"
                    style={{
                      marginTop: "88px",
                      marginLeft: "2px",
                      color: "yellow",
                    }}
                  />
                  </a>
                </div>
              </>
            ) : null}
            {auth.type === "superadmin" ? (
              <Form.Group
                style={{ 
                  width: "410px",
                  marginLeft: "250px",
                  backgroundColor:'white',
                  padding:'5px',
                  borderRadius:'10px',
                  marginTop: "10px",
                 }}>
                <ul style={{listStyle: "roman inside", marginTop:'10px'}}>
                  {projectfolders.map((el) =>
                    el.projfile_linkType === "matterport" ? (
                      <li
                        style={{ color: "black" }}
                        key={el.projfile_id}
                      >
                        <a
                          href={el.projfile_link}
                          target="_blank"
                          style={{ color: "gray", textDecoration: "none" }}
                        >
                          {el.projfile_link.slice(0,25)} 
                        </a>
                        <a href="#" title="Delete link"  onClick={(e) =>{deletefinalLink(e)}}>
                        <RemoveCircleIcon
                          id={el.projfile_id}
                          role="button"
                          style={{
                            color: "rgb(238, 94, 94)",
                            marginLeft: "5px",
                            fontSize: "25px",
                          }}                         
                        />
                        </a>
                      </li>
                    ) : null
                  )}
                </ul>
              </Form.Group>
            ) : null}
            {/*Matterport Link */}

            {/* Polycam Link */}
            {auth.type === "superadmin" ? (
              <div className="nineth">
                <Form.Group>
                  <Form.Label
                    style={{
                      width: "200px",
                      marginLeft: "70px",
                      marginTop: "85px",
                      color: "white",
                      fontSize: "20px",
                    }}
                  >
                    Polycam Link
                  </Form.Label>
                  <Form.Control
                    style={{
                      width: "700px",
                      textAlign: "left",
                      marginLeft: "250px",
                      marginTop: "-40px",
                    }}
                    type="text"
                    value={linkpolycam}
                    onChange={(e) =>{                         
                      setLinkpolycam(e.target.value)
                    }                      
                  }
                  />
                </Form.Group>
                <a href="#" title="Save link"  onClick={(e) => linkpolyContainer(e)}>
                <AddCircleOutlinedIcon
                  role="button"
                  id="polycam"
                  style={{
                    marginTop: "90px",
                    marginLeft: "5px",
                    color: "rgb(64, 192, 243)",
                  }}                 
                />
                </a>
                <a href="#" title="Clean"  onClick={(e)=>clearpolylinkSpace(e)}>
                <CleaningServicesIcon
                  role="button"
                  style={{
                    marginTop: "88px",
                    marginLeft: "2px",
                    color: "yellow",
                  }}                 
                />
                </a>
              </div>
            ) : null}
            {auth.type === "superadmin" ? (
              <Form.Group
              style={{ 
                width: "410px",
                marginLeft: "250px",
                backgroundColor:'white',
                padding:'5px',
                borderRadius:'10px',
                marginTop: "10px",
               }}           
               >
               <ul style={{listStyle: "roman inside", marginTop:'10px'}}>
                  {projectfolders.map((el) =>
                    el.projfile_linkType === "polycam" ? (
                      <li
                        style={{ color: "black" }}
                        key={el.projfile_id}
                      >
                        <a
                          href={el.projfile_link}
                          target="_blank"
                          style={{ color: "gray", textDecoration: "none" }}
                        >
                          {el.projfile_link.slice(0,25)} 
                        </a>
                        <a href="#" title="Delete link" onClick={(e) =>{deletefinalLink(e)}}>
                        <RemoveCircleIcon
                          id={el.projfile_id}
                          role="button"
                          style={{
                            color: "rgb(238, 94, 94)",
                            marginLeft: "5px",
                            fontSize: "25px",
                          }}                          
                        />
                        </a>
                      </li>
                    ) : null
                  )}
                </ul>
              </Form.Group>
            ) : null}
            {/* Polycam Link */}

            {/* Dronedeploy Link */}
            {auth.type === "superadmin" ? (
              <div className="nineth">
                <Form.Group>
                  <Form.Label
                    style={{
                      width: "200px",
                      marginLeft: "70px",
                      marginTop: "85px",
                      color: "white",
                      fontSize: "20px",
                    }}
                  >
                    Dronedeploy Link
                  </Form.Label>
                  <Form.Control
                    style={{
                      width: "700px",
                      textAlign: "left",
                      marginLeft: "250px",
                      marginTop: "-40px",
                    }}
                    type="text"
                    value={linkdronedeploy}
                    onChange={(e) =>                      
                      {
                        setLinkdronedeploy(e.target.value);
                      }                   
                    }
                  />
                </Form.Group>
                <a href="#" title="Save link"  onClick={(e) => linkdroneContainer(e)}>
                <AddCircleOutlinedIcon
                  role="button"
                  id="dronedeploy"
                  style={{
                    marginTop: "90px",
                    marginLeft: "5px",
                    color: "rgb(64, 192, 243)",
                  }}                 
                />
                </a>
                <a href="#" title="Clean"  onClick={(e)=>cleardronelinkSpace(e)}>
                <CleaningServicesIcon
                  role="button"
                  style={{
                    marginTop: "88px",
                    marginLeft: "2px",
                    color: "yellow",
                  }}                 
                />
                </a>
              </div>
            ) : null}
            {auth.type === "superadmin" ? (
              <Form.Group
               style={{
                width: "410px",
                marginLeft: "250px",
                backgroundColor:'white',
                padding:'5px',
                borderRadius:'10px',
                marginTop: "10px",             
              }}>
                 <ul style={{listStyle: "roman inside", marginTop:'10px'}}>
                  {projectfolders.map((el) =>
                    el.projfile_linkType === "dronedeploy" ? (
                      <li
                        style={{ color: "black" }}
                        key={el.projfile_id}
                      >
                        <a
                          href={el.projfile_link}
                          target="_blank"
                          style={{ color: "gray", textDecoration: "none" }}
                        >
                          {el.projfile_link.slice(0,25)} 
                        </a>
                        <a href="#" title="Delete link"  onClick={(e) =>{deletefinalLink(e)}}>
                            <RemoveCircleIcon
                             role="button"
                            id={el.projfile_id}
                            style={{
                            color: "rgb(238, 94, 94)",
                            marginLeft: "5px",
                            fontSize: "25px",
                           }}
                          />
                        </a>
                      </li>
                    ) : null
                  )}
                </ul>
              </Form.Group>
            ) : null}
            {/* Dronedeploy Link */}

            {/* Googledrive Link */}
            {auth.type === "superadmin" ? (
              <div className="nineth">
                <Form.Group>
                  <Form.Label
                    style={{
                      width: "200px",
                      marginLeft: "70px",
                      marginTop: "85px",
                      color: "white",
                      fontSize: "20px",
                    }}
                  >
                    Google Drive Folder
                  </Form.Label>
                  <Form.Control
                    style={{
                      width: "700px",
                      textAlign: "left",
                      marginLeft: "255px",
                      marginTop: "-40px",
                    }}
                    type="text"
                    value={linkgoogledrive}
                    onChange={(e) => setLinkgoogledrive(e.target.value)}
                  />
                </Form.Group>
                <a href="#" title="Save link"  onClick={(e) => linkgoogledriveContainer(e)}>
                <AddCircleOutlinedIcon
                  role="button"
                  id="googledrive"
                  style={{
                    marginTop: "90px",
                    marginLeft: "5px",
                    color: "rgb(64, 192, 243)",
                  }}                 
                />
                </a>
                <a href="#" title="Clean"  onClick={(e)=>cleargoogledrivelinkSpace(e)}>
                <CleaningServicesIcon
                  role="button"
                  style={{
                    marginTop: "88px",
                    marginLeft: "2px",
                    color: "yellow",
                  }}                 
                />
                </a>
              </div>
            ) : null}
            {auth.type === "superadmin" ?(
            <Form.Group
             style={{               
              width: "410px",
              marginLeft: "255px",
              backgroundColor:'white',
              padding:'5px',
              borderRadius:'10px',
              marginTop: "10px",            
              }}>
                <ul style={{listStyle: "roman inside", marginTop:'10px'}}>
                {projectfolders.map((el) =>
                  el.projfile_linkType === "googledrive" ? (
                    <li
                      style={{ color: "black"}}
                      key={el.projfile_id}
                    >
                      <a
                        href={el.projfile_link}
                        target="_blank"
                        style={{ color: "gray", textDecoration: "none" }}
                      >
                         {el.projfile_link.slice(0,25)} 
                      </a>
                      <a href="#" title="Delete link"  onClick={(e) => deletefinalLink(e)}>
                      <RemoveCircleIcon
                        id={el.projfile_id}
                        role="button"
                        style={{
                          color: "rgb(238, 94, 94)",
                          marginLeft: "5px",
                          fontSize: "25px",
                        }}                       
                      />
                      </a>
                    </li>
                  ) : null
                )}
              </ul>
            </Form.Group>):null }            
            {/* Googledrive Link */}
            
            {/*  MULTISECTION FOR PASTE LINKS */}

            {/*  ************************SEPARATOR******************************** */}

            {/* VREALISM FINAL PLANS */}
            {auth.type === "superadmin" ? (
              <>
                <Form.Group style={{display:'flex'}}>
             <p 
               style={{
                marginTop: "100px",
                marginLeft: "70px",
                fontWeight: "bold",
                color: "white",
                fontSize: "30px",
              }}>
                  Vrealism final plans
                  <InfoIcon style={{padding:"4px",color:"orange",marginLeft:"10px",marginTop: "6px"}}/>
                  <small style={{ fontSize: "10px"}}>
                   Paste and save the link in each category
                  </small>
              </p> 


            </Form.Group>              
                
                <hr
                  style={{
                    color: "white",
                    width: "1200px",
                    marginLeft: "70px",
                    marginTop: "-10px",
                  }}
                />
                <div className="tenth">
                  <Form.Group>
                    <Form.Label
                      style={{
                        width: "200px",
                        marginLeft: "70px",
                        color: "white",
                        fontSize: "20px",
                        marginTop: "40px",
                      }}
                    >
                      Type of file
                    </Form.Label>
                    <Form.Select
                      style={{ width: "250px", marginLeft: "70px" }}
                      aria-label="Default select example"
                      onChange={(e) =>{setFinalplantype(e.target.value)}}
                    >
                      <option style={{ color: "#aeb1b4" }}>
                        Choose a type
                      </option>
                      <option value="floorplan">Floor Plan</option>
                      <option value="siteplan">Site Plan</option>
                      <option value="roofplan">Roof Plan</option>
                      <option value="elevation">Elevation</option>
                      <option value="cad">Cad</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label
                      style={{
                        width: "200px",
                        marginLeft: "30px",
                        color: "white",
                        fontSize: "12px",
                        marginTop: "48px",
                        fontWeight:'bold',
                        display:'flex',
                      }}
                    >
                     <img src="img/drivelogo.png" width="30px" style={{marginRight:'9px'}}/>Google drive share file link
                    </Form.Label>
                    <Form.Control
                      style={{
                        width: "610px",
                        textAlign: "left",
                        marginLeft: "30px",
                      }}
                      type="text"
                      value={finalplanlink}
                      onChange={(e) =>{
                        setFinalplanlink(e.target.value);              
                      } }
                    />
                  </Form.Group>
                  <a href="#" title="Save link" onClick={(e) => linkfinalplanContainer(e)}>
                  <AddCircleOutlinedIcon
                    role="button"
                    style={{
                      marginTop: "85px",
                      marginLeft: "5px",
                      color: "rgb(64, 192, 243)",
                    }}                    
                  />
                  </a>
                  <a href="#" title="Clean" onClick={(e)=>clearfinalplanlinkSpace(e)}>
                  <CleaningServicesIcon
                    role="button"
                    style={{
                      marginTop: "83px",
                      marginLeft: "3px",
                      color: "yellow",
                    }}
                  />
                  </a>
                </div>

                
                <div className="eleventh">

                  <Form.Group style={{ marginLeft: "230px", marginTop: "10px"}}>
                    <Form.Label
                      style={{
                        width: "200px",
                        marginLeft: "-30px",
                        color: "white",
                        fontSize: "20px",
                      }}
                    >
                      Floor Plan:
                    </Form.Label>
                    <div
                      style={{
                        width: "410px",
                        marginLeft: "-30px",
                        backgroundColor:'white',
                        padding:'5px',
                        borderRadius:'10px'
                      }}
                    >
                       <ul style={{listStyle: "roman inside", marginTop:'10px'}}>
                        {projectfolders.map((el) =>
                          el.projfile_linkType === "floorplan" ? (
                            <li
                              style={{
                                color: "black"
                              }}
                              key={el.projfile_id}
                            >
                              <a
                                href={el.projfile_link}
                                target="_blank"
                                style={{
                                  color: "gray",
                                  textDecoration: "none",
                                }}
                              >
                                  {el.projfile_link.slice(0,25)} 
                              </a>
                              <a href="#" title="Delete link" onClick={(e) =>{deletefinalLink(e)} }>
                              <RemoveCircleIcon
                                id={el.projfile_id}
                                role="button"
                                style={{
                                  color: "rgb(238, 94, 94)",
                                  marginLeft: "5px",
                                  fontSize: "18px",
                                }}                                
                              />
                              </a>
                            </li>
                          ) : null
                        )}
                      </ul>
                    </div>
                  </Form.Group>

                  <Form.Group style={{ marginLeft: "230px", marginTop: "7px" }}>
                    <Form.Label
                      style={{
                        width: "200px",
                        marginLeft: "-30px",
                        color: "white",
                        fontSize: "20px",
                      }}
                    >
                      Site Plan:
                    </Form.Label>
                    <div
                      style={{
                        width: "410px",
                        marginLeft: "-30px",
                        backgroundColor:'white',
                        padding:'5px',
                        borderRadius:'10px',
                      }}
                    >
                       <ul style={{listStyle: "roman inside", marginTop:'10px'}}>
                        {projectfolders.map((el) =>
                          el.projfile_linkType === "siteplan" ? (
                            <li
                              style={{
                                color: "black"
                              }}
                              key={el.projfile_id}
                            >
                              <a
                                href={el.projfile_link}
                                target="_blank"
                                style={{
                                  color: "gray",
                                  textDecoration: "none",
                                }}
                              >
                                {el.projfile_link.slice(0,25)}
                              </a>
                              <a href="#" title="Delete link"  onClick={(e) =>{deletefinalLink(e)} }>
                              <RemoveCircleIcon
                                id={el.projfile_id}
                                role="button"
                                style={{
                                  color: "rgb(238, 94, 94)",
                                  marginLeft: "5px",
                                  fontSize: "18px",
                                }}
                              />
                              </a>
                            </li>
                          ) : null
                        )}
                      </ul>
                    </div>
                  </Form.Group>

                  <Form.Group style={{ marginLeft: "230px", marginTop: "7px" }}>
                    <Form.Label
                      style={{
                        width: "200px",
                        marginLeft: "-30px",
                        color: "white",
                        fontSize: "20px",
                      }}
                    >
                      Roof Plan:
                    </Form.Label>
                    <div
                      style={{
                        width: "410px",
                        marginLeft: "-30px",
                        backgroundColor:'white',
                        padding:'5px',
                        borderRadius:'10px',
                      }}
                    >
                       <ul style={{listStyle: "roman inside", marginTop:'10px'}}>
                        {projectfolders.map((el) =>
                          el.projfile_linkType === "roofplan" ? (
                            <li
                              style={{
                                color: "black"}}
                              key={el.projfile_id}
                            >
                              <a
                                href={el.projfile_link}
                                target="_blank"
                                style={{
                                  color: "gray",
                                  textDecoration: "none",
                                }}
                              >
                                {el.projfile_link.slice(0,25)}
                              </a>
                              <a href="#" title="Delete link" onClick={(e) =>{deletefinalLink(e)}}>
                              <RemoveCircleIcon
                                id={el.projfile_id}
                                role="button"
                                style={{
                                  color: "rgb(238, 94, 94)",
                                  marginLeft: "5px",
                                  fontSize: "18px",
                                }}                                
                              />
                              </a>
                            </li>
                          ) : null
                        )}
                      </ul>
                    </div>
                  </Form.Group>

                  <Form.Group style={{ marginLeft: "230px", marginTop: "7px" }}>
                    <Form.Label
                      style={{
                        width: "200px",
                        marginLeft: "-30px",
                        color: "white",
                        fontSize: "20px",
                      }}
                    >
                      Elevation:
                    </Form.Label>
                    <div
                      style={{
                        width: "410px",
                        marginLeft: "-30px",
                        backgroundColor:'white',
                        padding:'5px',
                        borderRadius:'10px',
                      }}
                    >
                     <ul style={{listStyle: "roman inside", marginTop:'10px'}}>
                        {projectfolders.map((el) =>
                          el.projfile_linkType === "elevation" ? (
                            <li
                              style={{
                                color: "black"
                              }}
                              key={el.projfile_id}
                            >
                              <a
                                href={el.projfile_link}
                                target="_blank"
                                style={{
                                  color: "gray",
                                  textDecoration: "none",
                                }}
                              >
                                {el.projfile_link.slice(0,25)}
                              </a>
                              <a href="#" title="Delete link"  onClick={(e) =>{deletefinalLink(e)}}>
                               <RemoveCircleIcon
                                id={el.projfile_id}
                                role="button"
                                style={{
                                  color: "rgb(238, 94, 94)",
                                  marginLeft: "5px",
                                  fontSize: "18px",
                                }}                              
                              />
                              </a>
                            </li>
                          ) : null
                        )}
                      </ul>
                    </div>
                  </Form.Group>

                  <Form.Group style={{ marginLeft: "230px", marginTop: "7px" }}>
                    <Form.Label
                      style={{
                        width: "200px",
                        marginLeft: "-30px",
                        color: "white",
                        fontSize: "20px",
                      }}
                    >
                      Cad:
                    </Form.Label>
                    <div
                      style={{
                        width: "410px",
                        marginLeft: "-30px",
                        backgroundColor:'white',
                        padding:'5px',
                        borderRadius:'10px',
                      }}
                    >
                       <ul style={{listStyle: "roman inside", marginTop:'5px'}}>
                        {projectfolders.map((el) =>
                          el.projfile_linkType === "cad" ? (
                            <li
                              style={{
                                color: "black"
                              }}
                              key={el.projfile_id}
                            >
                              <a
                                href={el.projfile_link}
                                target="_blank"
                                style={{
                                  color: "gray",
                                  textDecoration: "none",
                                }}
                              >
                                {el.projfile_link.slice(0,25)}
                              </a>
                              <a href="#" title="Delete link"   onClick={(e) =>{deletefinalLink(e)}}>
                              <RemoveCircleIcon
                                id={el.projfile_id}
                                role="button"
                                style={{
                                  color: "rgb(238, 94, 94)",
                                  marginLeft: "5px",
                                  fontSize: "18px",
                                }}                              
                              />
                              </a>
                            </li>
                          ) : null
                        )}
                      </ul>
                    </div>
                  </Form.Group>


                </div>
              </>
            ) : null}
            {/* VREALISM FINAL PLANS */}
            {/*  ************************SEPARATOR******************************** */}

            {/* INOICES */}
            {auth.type === "superadmin" ?(
             <>
              
            <Form.Group style={{display:'flex'}}>
             <p 
               style={{
                marginTop: "100px",
                marginLeft: "70px",
                fontWeight: "bold",
                color: "white",
                fontSize: "30px",
              }}>
                 Invoice generator
                  <InfoIcon style={{padding:"4px",color:"orange",marginLeft:"10px",marginTop: "6px"}}/>
                  <small style={{ fontSize: "10px"}}>
                   Check and update the invoice information
                  </small>
              </p> 


            </Form.Group>   
                <hr
                  style={{
                  color: "white",
                  width: "1200px",
                  marginLeft: "70px",
                  marginTop: "-10px",
                  }}
                />

                <Form.Group style={{ display:"flex"}}>
                      
                        <Form.Group style={{display:'flex'}}>

                          <Form.Label
                            style={{
                              width: "200px",
                              marginLeft: "100px",
                              marginTop: "50px",
                              color: "white",
                              fontSize: "20px",
                            }}
                          >
                            Wave app invoice ID
                          </Form.Label>

                          <Form.Control
                            type="text"
                            style={{
                              width: "150px",
                              height: "40px",
                              marginTop: "45px",
                            }}
                            onChange={(e) => setWaveappid(e.target.value)}

                            value={waveappid?waveappid:null}
                          />
                          {waveappid
                            ?null
                            :<small style={{color:'orange',marginTop:'55px',marginLeft:'5px'}}>
                            Pending
                          </small>}
                        </Form.Group>

                    
                        <Form.Label
                          style={{
                            width: "200px",
                            marginLeft: "150px",
                            marginTop: "50px",
                            color: "white",
                            fontSize: "20px",
                          }}
                        >
                          Wave status
                        </Form.Label>

                        <Form.Group style={{ marginTop: "10px", marginLeft: "-20px"}}>
                            {auth.type === "superadmin" ? (
                              invoicelist.map((list) => (
                                <Form.Check
                                  key={list.id}
                                  type="switch"
                                  label={list.name}
                                  id={list.name}
                                  style={{ color: "white" }}
                                  checked={list.name === invoicestatus ? true : false}
                                  onChange={(e) => handleInvoiceSwitch(list)}
                                />
                              ))
                            ) : (
                              <h1>No admin</h1>
                            )}
                        </Form.Group>
                        
                </Form.Group>


                <Form.Group style={{ display:"flex"}}>
                      <Form.Label
                        style={{
                          width: "200px",
                          marginLeft: "100px",
                          marginTop: "50px",
                          color: "white",
                          fontSize: "20px",
                        }}
                      >
                        Wave app link
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Paste the invoice link"
                        style={{
                          width: "650px",
                          height: "40px",
                          marginTop: "45px",
                          marginLeft: "5px",
                        }}
                        defaultValue={invoicelink}
                        onChange={(e) => setWaveapplink(e.target.value)}
                      />
                      <a href="#" title="Save invoice"  onClick={(e) => Sendinvoice(e)}>
                      <SendIcon
                        role="button"
                        style={{
                          color: "rgb(238, 94, 94)",
                          marginLeft: "5px",
                          fontSize: "38px",
                          marginTop: "48px",
                        }}                       
                      />
                      </a>
                        <p
                          ref={sendingInvoice}
                          style={{
                            color: "red",
                            marginTop: "55px",
                            marginLeft: "10px",
                          }}
                        >                          
                        </p>
                </Form.Group>

                <Form.Group style={{ marginTop:"80px"}}>
                </Form.Group>
              </>
            ):null}
            {/* INVOICES  */}
          </Form>
        )}
      </div>
    </Container>
  );
}
