import React from 'react'

const TextArea = ({chats}) => {



  return (

   <div className='chating'>
    <ul style={{marginTop: "5px", width:'210px', maxWidth:'210px'}}>
       {chats.map((chat)=>(
         <i style={{marginLeft:"-30px",display:"flex",flexDirection:"row",width:'200px',maxWidth:'200px'}}>
           <p style={{fontWeight:'bold', fontSize:'14px', marginTop:'2px', marginLeft:'5px'}}>{chat.client.name}</p>:
           <p style={{fontSize:"12px",marginLeft:"5px",marginTop:"4px", width:'200px', maxWidth:'200px'}}>{chat.sms}</p>
         </i>                                 
        ))}       
      </ul>
   </div>

  )
}

export default TextArea