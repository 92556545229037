import { useState } from 'react';
import './sidebaradmin.css';
import useAuth from '../../auth/useAuth';
import {
  LineStyle,
  Timeline,
  TrendingUp,
  PermIdentity,
  Storefront,
  AttachMoney,
  BarChart,
  MailOutline,
  DynamicFeed,
  ChatBubbleOutline,
  WorkOutline,
  Report,
  CloudDownload,
  SupervisorAccountOutlined,
  Diversity3Outlined,
  ArchitectureOutlined,
  SquareFootOutlined,
} from '@material-ui/icons';
import { Button, Dropdown } from 'react-bootstrap';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import { Link } from 'react-router-dom';

export default function Sidebaradmin() {
  const auth = useAuth();
  auth.setUser(window.localStorage.getItem('username'));
  const [project, setProject] = useState(false);
  const [setup, setSetup] = useState(false);
  const [searching, setSearching] = useState(false);
  const [home, setHome] = useState(false);
  return (
    <div style={{ height: 'auto' }}>
      {auth.user ? (
        <div className="sidebar" onMouseLeave={() => setSetup(false)}>
          <div className="sidebarWrapper">
            <div className="sidebarMenu">
              <ul className="sidebarList">
                <Link to="/" className="link">
                  <li className="sidebarListItem">
                    <HomeOutlinedIcon
                      style={{ fontSize: '40px' }}
                      onClick={() => {
                        /* auth.setIdcheck(auth.projid); */
                        auth.setIdcheck(
                          parseInt(window.localStorage.getItem('projectid')),
                        );
                        auth.setSearchi(false);
                      }}
                      onMouseEnter={() => setHome(true)}
                      onMouseLeave={() => setHome(false)}
                    />
                    {home && (
                      <small
                        style={{ fontWeight: 'bold', marginTop: '3px' }}
                        className="beauty"
                      >
                        <Button variant="primary">Dashboard</Button>
                      </small>
                    )}
                  </li>
                </Link>
              </ul>
            </div>

            <div className="sidebarMenu">
              <ul className="sidebarList">
                <Link
                  /* to="/#search" */ to={{
                    pathname: '/',
                    /*  search: "?sort=search", */
                    hash: '#search',
                    /*  state: { fromDashboard: true }, */
                  }}
                  className="link"
                >
                  <li
                    className="sidebarListItem"
                    onClick={() => auth.setSearchi(true)}
                  >
                    <ContentPasteSearchOutlinedIcon
                      style={{ fontSize: '40px' }}
                      onClick={() => auth.setIdcheck(auth.projid)}
                      onMouseEnter={() => setSearching(true)}
                      onMouseLeave={() => setSearching(false)}
                    />
                    {searching && (
                      <small
                        style={{ marginLeft: '5px', fontWeight: 'bold' }}
                        className="beauty"
                      >
                        <Button variant="success">Search project</Button>
                      </small>
                    )}
                  </li>
                </Link>
              </ul>
            </div>

            <div className="sidebarMenu">
              <ul className="sidebarList">
                <Link to="/makeproject" className="link">
                  <li className="sidebarListItem">
                    <FactCheckOutlinedIcon
                      style={{ fontSize: '40px' }}
                      onMouseEnter={() => setProject(true)}
                      onMouseLeave={() => setProject(false)}
                    />
                    {project && (
                      <small
                        style={{ marginLeft: '5px', fontWeight: 'bold' }}
                        className="beauty"
                      >
                        <Button variant="danger">Create Project</Button>
                      </small>
                    )}
                  </li>
                </Link>
              </ul>
            </div>

            {/* menu nuevo admin */}
            <div className="sidebarMenu">
              <ul className="sidebarList">
                <li className="sidebarListItem">
                  <SettingsSuggestOutlinedIcon
                    style={{ fontSize: '40px' }}
                    onMouseEnter={() => setSetup(true)}
                    /* onMouseLeave={() => setSetup(false)} */
                  />
                  {setup && (
                    <small
                      style={{ marginLeft: '5px', fontWeight: 'bold' }}
                      className="beauty"
                    >
                      <Dropdown>
                        <Dropdown.Toggle variant="dark" id="dropdown-basic">
                          Module admin
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item className="menudrop" href="#/action-1">
                            <Link to="/moduleadmin" className="link">
                              <SupervisorAccountOutlined />
                              Administrators
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item className="menudrop" href="#/action-2">
                            <FactCheckOutlinedIcon />
                            Leaders
                          </Dropdown.Item>
                          <Dropdown.Item className="menudrop" href="#/action-3">
                            <BadgeOutlinedIcon />
                            Employees
                          </Dropdown.Item>
                          <Dropdown.Item className="menudrop" href="#/action-3">
                            <SquareFootOutlined />
                            Architecs
                          </Dropdown.Item>
                          <Dropdown.Item className="menudrop" href="#/action-3">
                            <ModeOutlinedIcon />
                            Drafters
                          </Dropdown.Item>
                          <Dropdown.Item className="menudrop" href="#/action-3">
                            <EngineeringOutlinedIcon />
                            Surveyors
                          </Dropdown.Item>
                          <Dropdown.Item className="menudrop" href="#/action-3">
                            <HandshakeOutlinedIcon />
                            Clients
                          </Dropdown.Item>
                          <Dropdown.Item className="menudrop" href="#/action-3">
                            <HowToRegOutlinedIcon />
                            Roles
                          </Dropdown.Item>
                          <Dropdown.Item className="menudrop" href="#/action-3">
                            <FormatListNumberedOutlinedIcon />
                            Services
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </small>
                  )}
                </li>
              </ul>
            </div>
            {/* menu nuevo admin */}
          </div>
        </div>
      ) : null}
    </div>
  );
}
