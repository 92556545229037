import { Form } from 'react-bootstrap';
import Crudtableclientrow from '../crudtableclientrow/Crudtableclientrow';
import './crudtableclient.css';

export default function Crudtableclient({ datax }) {
  return (
    <table className="table" name="table">
      <thead
        style={{
          backgroundColor: '#031122',
          color: 'white',
          /* textAlign: 'center', */
          /* opacity: '0.8', */
          /* border: '2px dotted blue ', */
        }}
      >
        <tr /* style={{ border: '5px green dotted' }} */>
          <th /* style={{ border: '1px dotted white' }} */>
            <Form.Check
              type="checkbox"
              checked=""
              defaultValue="Bob"
              onChange={() => {}}
            />
          </th>
          <th style={{ border: '1px dotted white' }}>Client name</th>
          <th style={{ border: '1px dotted white' }}>Client email</th>
          <th style={{ border: '1px dotted white' }}>Company</th>
          <th style={{ border: '1px dotted white' }}>Active</th>
          <th style={{ border: '1px dotted white' }}>Role</th>
          <th style={{ border: '1px dotted white' }}>Type</th>
          <th style={{ border: '1px dotted white' }}>Options</th>
        </tr>
      </thead>
      <tbody /* style={{ border: '6px dotted red' }} */>
        {datax && datax.map((el) => <Crudtableclientrow el={el} />)}
      </tbody>
    </table>
  );
}
